import {useContext, useState} from 'react'
import DndTable from '../../../../../components/dndTable/DndTable'
import AddBoardModuleModal from '../../modals/AddBoardModuleModal'
import {BoardSettingsPageContext} from '../../../context'
import ModuleActionsOverlay from '../../overlays/ModuleActionsOverlay'

export default function ModulesTable() {
  const [showModal, setShowModal] = useState(false)
  const {
    boardModulesList,
    isLoadingBoardModulesList,
    updateModuleTableSorting,
    hasBoardSettingsPermission,
  } = useContext(BoardSettingsPageContext)

  const handleUpdatedTableRows = (tableData: any) => {
    const moduleTableRowOrder = {
      module_ids: tableData.map((item: any) => item.id),
    }
    updateModuleTableSorting(moduleTableRowOrder)
  }

  const tableData = boardModulesList?.map((row: any) => {
    const rows = [
      {
        columnName: 'NAME',
        headerClass: 'w-100 min-w-120px',
        columnData: row.moduleName,
      },
      {
        columnName: 'CARDS',
        headerClass: 'min-w-100px',
        columnData: row.cards,
      },
    ]

    if (hasBoardSettingsPermission) {
      rows.push({
        columnName: 'ACTION',
        headerClass: 'w-80px text-center',
        columnData: <ModuleActionsOverlay moduleData={row} />,
      })
    }

    return {
      id: row.moduleId,
      tableData: rows,
    }
  })

  return (
    <div className='position-relative'>
      <DndTable
        data={tableData}
        isLoading={isLoadingBoardModulesList}
        handleUpdatedTableRows={handleUpdatedTableRows}
        isDraggable={hasBoardSettingsPermission}
      />
      {hasBoardSettingsPermission && (
        <div className='d-flex justify-content-end'>
          {!isLoadingBoardModulesList && (
            <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
              <i className='fa-solid fa-plus'></i>Add Module
            </button>
          )}
          {showModal && (
            <AddBoardModuleModal
              show={showModal}
              onClose={() => setShowModal(false)}
              isEdit={false}
            />
          )}
        </div>
      )}
    </div>
  )
}
