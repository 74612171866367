import {useContext, useState} from 'react'
import {ProjectMembersPageContext} from '../../context'
import Search from '../../../../components/Search'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import MultiSelect from '../../../../components/MultiSelect/MultiSelect'
import {useForm} from 'react-hook-form'

const MemberPageHeader = () => {
  const {
    hasMembersPermission,
    onSearch,
    membersList,
    addProjectMembers,
    isLoadingOperation,
    isLoadingProjectMembersList,
  } = useContext(ProjectMembersPageContext)
  const [showModal, setShowModal] = useState(false)
  const {control, handleSubmit} = useForm()

  const onAddMember = (data: any) => {
    addProjectMembers(data)
  }

  return (
    <div className='d-flex justify-content-between mb-10'>
      <Search onSearch={onSearch} />
      {hasMembersPermission && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Add Member
        </button>
      )}
      {showModal && (
        <ConfirmationModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onAction={handleSubmit(onAddMember)}
          title={'Add Member'}
          actionName='Add'
          actionBtnClass='btn-primary'
          isOperationLoading={isLoadingOperation}
          isDataLoading={isLoadingProjectMembersList}
          body={
            <MultiSelect
              id='add-project-members'
              control={control}
              registerKey='usernames'
              options={membersList}
              labelKey='name'
              valueKey='username'
            />
          }
        />
      )}
    </div>
  )
}

export {MemberPageHeader}
