import {AgileBoardPageContext} from '../context'
import {useGetAgileBoardStructure} from '../hooks/agileBoard/useGetAgileBoardStructure'
import {useGetAgileBoardData} from '../hooks/agileBoard/useGetAgileBoardData'
import useUpdateAgileBoard from '../hooks/agileBoard/useUpdateAgileBoard'
import useAgileBoardOperations from '../hooks/agileBoard/useAgileBoardOperations'
import TicketsBoard from '../components/TicketsBoard'
import {AgileBoardPageHeader} from '../sections/headers/AgileBoardPageHeader'
import usePermission from '../../../hook/usePermission'
import useGetProjectKanbanStages from '../hooks/agileBoard/useGetProjectKanbanStages'
import useGetProjectModules from '../hooks/agileBoard/useGetProjectModules'
import useGetProjectStatuses from '../hooks/agileBoard/useGetProjectStatuses'
import useGetProjectPriorites from '../hooks/agileBoard/useGetProjectPriorites'
import useGetProjectMembers from '../hooks/agileBoard/useGetProjectMembers'

function AgileBoard() {
  const {hasPermission} = usePermission()
  const hasBoardPermission = hasPermission('projects_all projects', 'write')
  const {agileBoardStructure, isLoading: isLoadingBoardStructure} = useGetAgileBoardStructure()
  const {agileBoardData, isLoading: isLoadingAgileBoardData} = useGetAgileBoardData()
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()
  const {projectKanbanStages, isLoading: isLoadingProjectKanbanStages} = useGetProjectKanbanStages()
  const {projectModules, isLoading: isLoadingProjectModules} = useGetProjectModules()
  const {projectStatuses, isLoading: isLoadingProjectStatuses} = useGetProjectStatuses()
  const {projectPriorities, isLoading: isLoadingProjectPriorities} = useGetProjectPriorites()
  const {createTicket, isLoading: isLoadingTicketOperation} = useAgileBoardOperations()
  const {updateAgileBoard, isLoading: isUpdatingAgileBoard} = useUpdateAgileBoard()

  const contextValue = {
    hasBoardPermission,

    agileBoardStructure,
    isLoadingBoardStructure,

    agileBoardData,
    isLoadingAgileBoardData,

    projectMembers,
    isLoadingProjectMembers,

    projectKanbanStages,
    isLoadingProjectKanbanStages,

    projectModules,
    isLoadingProjectModules,

    projectStatuses,
    isLoadingProjectStatuses,

    projectPriorities,
    isLoadingProjectPriorities,

    createTicket,
    isLoadingTicketOperation,

    updateAgileBoard,
    isUpdatingAgileBoard,
  }

  return (
    <>
      <AgileBoardPageContext.Provider value={contextValue}>
        <AgileBoardPageHeader />
        <TicketsBoard />
      </AgileBoardPageContext.Provider>
    </>
  )
}

export default AgileBoard
