import {useForm, Controller} from 'react-hook-form'
import Select from 'react-select'
import useGetGlobalCustomFieldsList from '../../../projectsSettings/hooks/customFields/useGetGlobalCustomFieldsList'
import {BoardSettingsPageContext} from '../../context'
import {useContext} from 'react'
import {AddBoardCustomFieldsModalTypes} from '../../interfaces'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'

const AddBoardCustomFieldsModal = ({
  show,
  onClose,
  isEdit,
  customFieldData,
}: AddBoardCustomFieldsModalTypes) => {
  const {globalCustomFieldsList} = useGetGlobalCustomFieldsList()
  const {
    createCustomField,
    updateCustomField,
    isLoadingBoardCustomFieldsList,
    isLoadingCustomFieldOperation,
  } = useContext(BoardSettingsPageContext)
  const {handleSubmit, control, register} = useForm<any>()

  const onSubmit = (data: any) => {
    if (!isEdit) {
      const createCustomFieldData = {
        name: data.customFields.label,
        customfield_meta_id: data.customFields.value,
      }
      createCustomField(createCustomFieldData)
    } else {
      const cFieldId = customFieldData?.id
      const updatedCustomFieldData = {
        name: data.custom_field_name,
      }
      updateCustomField(cFieldId, updatedCustomFieldData)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Field`}
      onAction={handleSubmit(onSubmit)}
      actionName={`${isEdit ? 'Update' : 'Save'}`}
      actionBtnClass='btn-primary'
      isDataLoading={isLoadingBoardCustomFieldsList}
      isOperationLoading={isLoadingCustomFieldOperation}
      body={
        <>
          {customFieldData && (
            <div className='mb-8'>
              <label htmlFor={`custom_field_name`} className='required form-label'>
                Field Name
              </label>
              <input
                type='text'
                id={`custom_field_name`}
                className={`form-control`}
                placeholder='Enter new name'
                defaultValue={customFieldData.custom_field_name}
                {...register('custom_field_name', {required: true})}
              />
            </div>
          )}

          {!isEdit && (
            <>
              <label htmlFor={`customFields`} className='required form-label'>
                Custom Field Type
              </label>
              <Controller
                name='customFields'
                control={control}
                render={({field, fieldState}) => (
                  <>
                    <Select
                      {...field}
                      className={`react-select-styled ${fieldState.invalid ? 'is-invalid' : ''}`}
                      placeholder='Select field type'
                      isDisabled={customFieldData ? true : false}
                      options={globalCustomFieldsList.map((item: any) => ({
                        label: item.custom_field_name,
                        value: item.custom_field_id,
                      }))}
                      defaultValue={
                        customFieldData
                          ? {
                              label: customFieldData.custom_field_name,
                              value: customFieldData.id,
                            }
                          : null
                      }
                    />
                    {fieldState.invalid && (
                      <div className='invalid-feedback'>Custom field name is required.</div>
                    )}
                  </>
                )}
                rules={!isEdit ? {required: 'Custom field name is required'} : undefined}
              />
            </>
          )}
        </>
      }
    />
  )
}

export default AddBoardCustomFieldsModal
