// import '../scss/task-card.scss'
import {useEffect, useRef} from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {useContext} from 'react'
import {DndBoardPageContext} from '../context'

function TaskCard({task}: any) {
  const {handleTicketLayout, dimensions, setDimensions} = useContext(DndBoardPageContext)
  const cardRef: any = useRef<HTMLDivElement>(null)
  const {setNodeRef, attributes, listeners, transform, transition, isDragging} = useSortable({
    id: task.ticketID,
    data: {
      type: 'Task',
      task,
    },
  })

  useEffect(() => {
    if (cardRef.current) {
      const {width, height} = cardRef.current.getBoundingClientRect()
      setDimensions((prev: any) => ({
        ...prev,
        [task.ticketID]: {width, height},
      }))
    }
    //eslint-disable-next-line
  }, [isDragging, task.ticketID])

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={{
          ...style,
          width: dimensions[task.ticketID]?.width,
          height: dimensions[task.ticketID]?.height,
        }}
        className='dummy-background-task-container rounded-1 border border-2'
      />
    )
  }

  const ticketData = task

  return (
    <div
      ref={(node) => {
        setNodeRef(node)
        cardRef.current = node
      }}
      style={style}
      {...attributes}
      {...listeners}
      className='board-card-item text-muted text-hover-primary cursor-pointer border-gray-300 border-2 d-flex border rounded-1 align-items-center px-4 py-3 position-relative'
    >
      {handleTicketLayout({ticketData})}
    </div>
  )
}

export default TaskCard
