import {useContext} from 'react'
import {ProjectsListPageContext} from '../../context'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectArchiveConfirmationModalTypes} from '../../interfaces'

const ProjectArchiveConfirmationModal = ({
  projectDetails,
  show,
  onClose,
}: ProjectArchiveConfirmationModalTypes) => {
  const {updateProjectDetails, isOperationLoading, isLoading} = useContext(ProjectsListPageContext)

  const handleArchiveproject = () => {
    if (projectDetails) {
      const updatedDetails = {
        isArchived: !projectDetails?.isArchived,
      }
      updateProjectDetails(updatedDetails, projectDetails.id)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      body={
        <p>
          Are you sure you want to {projectDetails?.isArchived ? 'unarchive' : 'archive'} the
          project?
        </p>
      }
      onAction={handleArchiveproject}
      isOperationLoading={isOperationLoading}
      isDataLoading={isLoading}
      actionBtnClass={projectDetails?.isArchived ? 'btn-primary' : 'btn-danger'}
    />
  )
}

export default ProjectArchiveConfirmationModal
