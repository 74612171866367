import {useContext} from 'react'
import Loading from '../../../../loading'
import DndTable from '../../../../../components/dndTable/DndTable'
import {ProjectsDefaultColumnsPageContext} from '../../../context'
import DefaultColumnsActionsOverlay from '../../actionsDropdowns/DefaultColumnsActionsOverlay'
import usePermission from '../../../../../hook/usePermission'

export default function DefaultColumnsTable() {
  const {hasPermission} = usePermission()
  const {
    updateGlobalDefaultColumnsTableSorting,
    globalDefaultColumnsList,
    isLoadingGlobalDefaultColumnsList,
  } = useContext(ProjectsDefaultColumnsPageContext)

  const handleUpdatedTableRows = (tableData: any) => {
    const defaultColumnsTableRowOrder = {
      default_columns_ids: tableData.map((item: any) => item.id),
    }
    updateGlobalDefaultColumnsTableSorting(defaultColumnsTableRowOrder)
  }

  const formattedData = globalDefaultColumnsList?.map((gColumns: any) => {
    const tableData = [
      {
        columnName: 'NAME',
        headerClass: 'min-w-300px',
        columnData: gColumns.name,
      },
    ]

    if (hasPermission('projects_settings', 'write')) {
      tableData.push({
        columnName: 'ACTION',
        headerClass: 'w-80px text-center',
        columnData: (
          <DefaultColumnsActionsOverlay
            defaultColumnId={gColumns.id}
            defaultColumnDetails={gColumns}
          />
        ),
      })
    }

    return {
      id: gColumns.id,
      tableData: tableData,
    }
  })

  return (
    <div className='position-relative'>
      {isLoadingGlobalDefaultColumnsList && <Loading />}
      <DndTable
        data={formattedData}
        isLoading={isLoadingGlobalDefaultColumnsList}
        handleUpdatedTableRows={handleUpdatedTableRows}
        isDraggable={hasPermission('projects_settings', 'write')}
      />
    </div>
  )
}
