import useToastify from '../../../../hook/useToastify'
import {useState} from 'react'
import useGetGlobalDefaultColumnsList from './useGetGlobalDefaultColumnsList'
import { addGlobalDefaultColumn, editGlobalDefaultColumn, removeGlobalDefaultColumn, updateDefaultColumnsTableSorting } from '../../services'


function useGlobalDefaultColumnsOperations() {
  const {toastMessage} = useToastify()
  const {refetchGlobalDefaultColumnsList} = useGetGlobalDefaultColumnsList()
  const [isLoading, setIsLoading] = useState(false)

  async function createGlobalDefaultColumn(defaultColumnDetails: any) {
    setIsLoading(true)
    const res: any = await addGlobalDefaultColumn(defaultColumnDetails)
    if (res.status === 200) {
      toastMessage('success', `Default column created successfully`)
      refetchGlobalDefaultColumnsList()
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateGlobalDefaultColumn(defaultColumnId: string, defaultColumnDetails: any) {
    setIsLoading(true)
    const res: any = await editGlobalDefaultColumn(defaultColumnId, defaultColumnDetails)
    if (res.status === 200) {
      toastMessage('success', `Default column updated successfully`)
      setIsLoading(false)
      refetchGlobalDefaultColumnsList()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateGlobalDefaultColumnsTableSorting(defaultColumnsTableRowOrder: any) {
    setIsLoading(true)
    const tableName = 'columns'
    const res: any = await updateDefaultColumnsTableSorting(tableName, defaultColumnsTableRowOrder)
    if (res.status === 200) {
      toastMessage('success', `Updated successfully`)
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function deleteGlobalDefaultColumn(defaultColumnId: string) {
    setIsLoading(true)
    const res: any = await removeGlobalDefaultColumn(defaultColumnId)
    if (res.status === 200) {
      toastMessage('success', `Default column deleted successfully`)
      setIsLoading(false)
      refetchGlobalDefaultColumnsList()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  return {
    createGlobalDefaultColumn,
    updateGlobalDefaultColumn,
    updateGlobalDefaultColumnsTableSorting,
    deleteGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation: isLoading,
  }
}

export default useGlobalDefaultColumnsOperations
