import {useContext} from 'react'
import {TicketDetailsPageContext} from '../../context'
import {TextArea} from '../../../../components/TextArea'
import HtmlPlaceholder from '../../components/HtmlPlaceholder'

const TicketDetailsPageHeader = ({register, onSave, ticketTitle}: any) => {
  const {ticketDetails, isTicketEditable, setIsTicketEditable} =
    useContext(TicketDetailsPageContext)

  const onEnterInTitle = (e: any) => {
    if (e.key === 'Enter') {
      onSave()
      e.preventDefault()
    }
  }

  return (
    <div className={`row ${isTicketEditable ? 'mb-4' : 'mb-10'}`}>
      <div className='col-md-8'>
        {
          <h6 className='text-primary mb-2 fw-bold text-underline d-flex align-items-center'>
            <HtmlPlaceholder className='w-100px'>
              #{ticketDetails['card_identifier']}
            </HtmlPlaceholder>
          </h6>
        }
        <div className='d-flex gap-3'>
          {isTicketEditable ? (
            <TextArea
              id='edit-ticket-title'
              register={register('title')}
              onKeyDown={onEnterInTitle}
              className='w-100'
              rows={1}
              value={ticketTitle}
              inputClass='fs-1 fw-bold px-0 pt-2 pb-0 text-dark border-bottom-2 border-top-0 border-start-0 border-end-0 resize-none rounded-0 min-h-40px'
            />
          ) : (
            <h1
              className='mb-0 cursor-pointer bg-hover-light-primary'
              onClick={() => setIsTicketEditable(true)}
            >
              <HtmlPlaceholder className='w-200px'>{ticketTitle}</HtmlPlaceholder>
            </h1>
          )}

          <span
            className='p-2 h-30px w-30px d-flex justify-content-center cursor-pointer align-items-center'
            onClick={() => setIsTicketEditable(true)}
          >
            <i className='fa-solid fa-pen fs-6 text-hover-primary'></i>
          </span>
        </div>
      </div>
    </div>
  )
}

export default TicketDetailsPageHeader
