import {isEmptyObject} from 'jquery'

export function dataToInquiriesPage(data: any) {
  const inquiries: any = []

  // Return an empty array if data is falsy
  if (!data) {
    return []
  }

  // Iterate through the data and push the original keys into inquiries
  data.forEach((element: any) =>
    inquiries.push({
      id: element['id'],
      customer_id: element['customer_id'],
      customer_name: element['customer_name'],
      customer_email: element['customer_email'],
      product_id: element['product_id'],
      date_submitted: element['date_submitted'],
      message: element['message'],
      status: element['status'],
      updated_by: element['updated_by'],
      updated_at: element['updated_at'],
      rep_email: element['rep_email'],
      rep_name: element['rep_name'],
      product_name: element['product_name'],
      sku: element['sku'] || '-',
    })
  )

  return inquiries
}

export const defaultColumns = [
  {date_submitted: 'DATE SUBMITTED'},
  {product_name: 'PRODUCT NAME', default: true},
  {sku: 'SKU', default: true},
  {message: 'MESSAGE', default: true},
  {customer_name: 'CUSTOMER'},
  {rep_name: 'CUSTOMER REP'},
  {status: 'STATUS'},
  {action: 'ACTION', default: true},
]

export const dataToSortableColumns = (columns: any[], Overlay: any) => {
  const sortableColumns = columns.map((column: any) => {
    const [key, label] = Object.entries(column)[0]

    if (key === 'action') {
      return {
        key,
        label,
        headerStyle: 'min-w-80px',
        render: (row: any) => Overlay(row),
      }
    }

    // Set default headerStyle based on key or any other rule
    let headerStyle = 'min-w-175px'
    let isSorted = false

    if (key === 'product_name') {
      headerStyle = 'min-w-400px'
      isSorted = true
    } else if (key === 'sku') {
      headerStyle = 'min-w-150px w-150px'
      isSorted = true
    } else if (key === 'message') {
      headerStyle = 'min-w-300px'
    } else if (key === 'status') {
      headerStyle = 'min-w-80px'
      isSorted = true
    } else if (key === 'date_submitted') {
      headerStyle = 'min-w-175px'
      isSorted = true
    } else if (key === 'customer_name') {
      headerStyle = 'min-w-200px'
      isSorted = true
    } else if (key === 'rep_name') {
      headerStyle = 'min-w-200px'
      isSorted = true
    }

    return {
      key,
      label,
      headerStyle,
      isSorted,
      render: (row: any) => Overlay(row),
    }
  })

  return sortableColumns
}

export const formatToLabelValue = (data: any) => {
  if (isEmptyObject(data)) return []
  return Object.entries(data)?.map(([email, name]) => ({
    label: name,
    value: email,
  }))
}

export const removeColumn = (data: any[], columnKey: string) => {
  return data.filter((item: any) => !item.hasOwnProperty(columnKey))
}

export const trimMessage = (message: any) => {
  if (message.length > 150) {
    return message.substring(0, 150) + '...'
  }
  return message
}
