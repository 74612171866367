import './textEditorStyles.css'
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin'
import {LexicalComposer} from '@lexical/react/LexicalComposer'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary'
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import MentionsPlugin from './plugins/MentionsPlugin'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import {useEffect, useState} from 'react'
import KeywordsPlugin from './plugins/KeywordsPlugin'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin'
import ImagesPlugin from './plugins/ImagesPlugin'
import InlineImagePlugin from './plugins/InlineImagePlugin'
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin'
import {TablePlugin} from '@lexical/react/LexicalTablePlugin'
import {LayoutPlugin} from './plugins/LayoutPlugin/LayoutPlugin'
import CollapsiblePlugin from './plugins/CollapsiblePlugin'
import {SettingsContext, useSettings} from './context/SettingsContext'
import {CAN_USE_DOM} from './shared/canUseDOM'
import {HashtagPlugin} from '@lexical/react/LexicalHashtagPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import {SharedHistoryContext, useSharedHistoryContext} from './context/SharedHistoryContext'
import Placeholder from './components/Placeholder'
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {CheckListPlugin} from '@lexical/react/LexicalCheckListPlugin'
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin'
import TableCellResizer from './plugins/TableCellResizer'
import {ClickableLinkPlugin} from '@lexical/react/LexicalClickableLinkPlugin'
import {HorizontalRulePlugin} from '@lexical/react/LexicalHorizontalRulePlugin'
import TabFocusPlugin from './plugins/TabFocusPlugin'
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin'
import {CharacterLimitPlugin} from '@lexical/react/LexicalCharacterLimitPlugin'
// import ContextMenuPlugin from './plugins/ContextMenuPlugin'
import TextEditorNodes from './nodes/TextEditorNodes'
import {OnChangePlugin} from './plugins/LexicalOnChangePlugin'
import textEditorTheme from './textEditorTheme'
import {TablePlugin as NewTablePlugin, TableContext} from './plugins/TablePlugin'
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin'
import {useRef} from 'react' // Import useRef for referencing the editor div
import {mergeRefs} from './utils/common'
import {isEmpty} from '../../utils/common'
import {$generateNodesFromDOM} from '@lexical/html'
import {$getRoot} from 'lexical'

interface TextEditorTypes {
  id: string
  inputClass?: string
  onChange?: (value: any) => void
  readOnly?: boolean
  disabled?: any
  maxLength?: number
  rules?: any
  textEditorRef?: any
  value?: any
  defaultValue?: string | null
  hasDefaultValue?: boolean
  isChangeOnSave?: boolean
  isLoadingOperation?: boolean
  isLoadingData?: boolean
  isReadOnly?: boolean
  editorClass?: string
  isClearChanges?: boolean
}

const EditorComponent = ({
  id,
  inputClass = '',
  onChange,
  readOnly,
  disabled,
  value,
  defaultValue = null,
  textEditorRef,
  maxLength = 62500,
  isChangeOnSave = false,
  isLoadingOperation = undefined,
  isLoadingData = undefined,
  isReadOnly = false,
  editorClass = '',
  isClearChanges = false,
}: TextEditorTypes) => {
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<any | null>(null)
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState(false)
  const [editorValue, setEditorValue] = useState<string>('<p class="texteditor_paragraph"></p>')
  const [skeletonPlaceholders, setSkeletonPlaceholders] = useState<JSX.Element[]>([])
  const [editorKey, setEditorKey] = useState<number>(0)
  const shouldShowSkeletons = isLoadingData && (isEmpty(defaultValue) || isEmpty(editorValue))
  const placeholder = (
    <Placeholder>{isLoadingData || isReadOnly ? '' : 'Enter text here...'}</Placeholder>
  )
  const {
    settings: {tableCellMerge, tableCellBackgroundColor, isCharLimit},
  } = useSettings()
  const {historyState} = useSharedHistoryContext()
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }
  const editorRef = useRef<HTMLDivElement | null>(null)
  const textBoxEditor = useRef<HTMLDivElement | null>(null)

  function convertHTMLToLexical(editor: any) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(defaultValue ? defaultValue : '', 'text/html')
    const nodes = $generateNodesFromDOM(editor, doc)
    nodes.forEach((node) => editor.update(() => $getRoot().append(node)))
  }

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      setEditorKey((prevKey) => prevKey + 1)
    }
  }, [defaultValue])

  const textEditorConfig = {
    namespace: 'Projects',
    nodes: TextEditorNodes,
    onError: (error: Error) => {
      throw error
    },
    theme: textEditorTheme,
    editorState: (editor: any) =>
      editor.update(() => {
        $getRoot().clear()
        convertHTMLToLexical(editor)
      }),
  }

  useEffect(() => {
    if (textBoxEditor?.current) {
      if (isReadOnly || disabled) {
        textBoxEditor.current.setAttribute('contenteditable', 'false')
      } else {
        textBoxEditor.current.setAttribute('contenteditable', 'true')
      }
    }
  })

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport)
      }
    }
    updateViewPortWidth()
    window.addEventListener('resize', updateViewPortWidth)

    return () => {
      window.removeEventListener('resize', updateViewPortWidth)
    }
  }, [isSmallWidthViewport])

  const onChangeTextEditor = (value: string) => {
    setEditorValue(value)
    if (!isChangeOnSave) {
      onChange?.(value)
    }
  }

  const onSave = () => {
    onChange?.(editorValue)
    if (isLoadingOperation === undefined) {
      setIsDirty(false)
    }
  }

  const onCancel = () => {
    setEditorKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    if (isClearChanges) {
      onCancel()
    }
  }, [isClearChanges])

  useEffect(() => {
    if (isLoadingData && !isLoadingOperation) {
      setIsDirty(false)
    }
  }, [isLoadingData, isLoadingOperation])

  useEffect(() => {
    const updatePlaceholders = () => {
      if (editorRef.current) {
        const editorHeight = editorRef.current.clientHeight // Get the height of the editor
        const numPlaceholders = Math.floor(editorHeight / 16) // Calculate number of placeholders (1em = 16px)
        const newPlaceholders = Array.from({length: numPlaceholders}, (_, index) => {
          const randomColSize = Math.floor(Math.random() * 11) + 1 // Random column size between 1 and 12
          return (
            <div
              key={index}
              className={`placeholder col-${randomColSize} rounded-3 mt-2 bg-secondary  cursor-default`}
            ></div>
          )
        })
        setSkeletonPlaceholders(newPlaceholders)
      }
    }

    updatePlaceholders()
    window.addEventListener('resize', updatePlaceholders)

    return () => {
      window.removeEventListener('resize', updatePlaceholders)
    }
  }, [])

  return (
    <>
      <SettingsContext>
        <LexicalComposer initialConfig={textEditorConfig} key={editorKey}>
          <SharedHistoryContext>
            <TableContext>
              <div className={`editor-container ${editorClass}`}>
                {!shouldShowSkeletons && !isReadOnly && (
                  <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} disabled={disabled} />
                )}
                <div className='editor-inner'>
                  <AutoFocusPlugin />
                  <MentionsPlugin />
                  <HashtagPlugin />
                  <KeywordsPlugin />
                  <AutoLinkPlugin />
                  <HistoryPlugin externalHistoryState={historyState} />
                  <RichTextPlugin
                    contentEditable={
                      <div className='editor-scroller'>
                        <div
                          className={`editor position-relative ${
                            isLoadingData ? `overflow-hidden ${inputClass}` : ''
                          }`}
                          ref={mergeRefs(editorRef, onRef, textEditorRef)}
                        >
                          {!shouldShowSkeletons && (
                            <ContentEditable
                              className={
                                `editor-input ${isReadOnly ? 'p-2 pb-10' : 'min-h-300px '}` + inputClass
                              }
                              onClick={(e) => e.preventDefault()}
                              ref={textBoxEditor}
                            />
                          )}
                          {shouldShowSkeletons && (
                            <p
                              className={`d-flex gap-3 position-absolute w-100 top-0 right-0 row placeholder-glow `}
                            >
                              {skeletonPlaceholders}
                            </p>
                          )}
                        </div>
                      </div>
                    }
                    placeholder={placeholder}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <MarkdownShortcutPlugin />
                  <CodeHighlightPlugin />
                  <ListPlugin />
                  <CheckListPlugin />
                  <ListMaxIndentLevelPlugin maxDepth={7} />
                  <TablePlugin
                    hasCellMerge={tableCellMerge}
                    hasCellBackgroundColor={tableCellBackgroundColor}
                  />
                  <TableCellResizer />
                  <NewTablePlugin cellEditorConfig={textEditorConfig}>
                    <AutoFocusPlugin />
                    <RichTextPlugin
                      contentEditable={<ContentEditable className='TableNode__contentEditable' />}
                      placeholder={null}
                      ErrorBoundary={LexicalErrorBoundary}
                    />
                    <MentionsPlugin />
                    <ImagesPlugin captionsEnabled={false} />
                    <LinkPlugin />
                  </NewTablePlugin>
                  <ImagesPlugin />
                  <InlineImagePlugin />
                  <LinkPlugin />
                  <ClickableLinkPlugin />
                  <HorizontalRulePlugin />
                  <TabFocusPlugin />
                  <TabIndentationPlugin />
                  <CollapsiblePlugin />
                  <LayoutPlugin />
                  {!disabled && !isReadOnly && <FloatingTextFormatToolbarPlugin />}
                  {floatingAnchorElem && !isSmallWidthViewport && (
                    <>
                      <FloatingLinkEditorPlugin
                        anchorElem={floatingAnchorElem}
                        isLinkEditMode={isLinkEditMode}
                        setIsLinkEditMode={setIsLinkEditMode}
                      />
                      <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
                    </>
                  )}
                  {!shouldShowSkeletons && !isReadOnly && !disabled && (
                    <CharacterLimitPlugin
                      charset={isCharLimit ? 'UTF-16' : 'UTF-8'}
                      maxLength={maxLength}
                    />
                  )}
                  {/* <ContextMenuPlugin /> */}
                  <OnChangePlugin
                    onChange={onChangeTextEditor}
                    setIsDirty={setIsDirty}
                    defaultValue={defaultValue}
                  />
                </div>
                {isDirty && isChangeOnSave && !isReadOnly && (
                  <div className='editor-save position-sticky bottom-0 d-flex gap-3 align-items-center justify-content-end border-top py-3 px-5 '>
                    <button className='btn btn-outline bg-white' onClick={onCancel}>
                      Cancel
                    </button>
                    <button
                      className='btn btn-primary'
                      onClick={onSave}
                      disabled={isLoadingOperation}
                    >
                      {isLoadingOperation ? 'Saving...' : 'Save'}
                      {isLoadingOperation && (
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </TableContext>
          </SharedHistoryContext>
        </LexicalComposer>
      </SettingsContext>
    </>
  )
}

export default EditorComponent
