// import '../scss/column-container.scss'
import {SortableContext, useSortable} from '@dnd-kit/sortable'
import {useContext, useMemo} from 'react'
import TaskCard from './TaskCard'
import {DndBoardPageContext} from '../context'

function ColumnContainer({column, tasks}: any) {
  const {isCreateCardFromColumn, handleAddCardFromColumn, hasPermission} = useContext(DndBoardPageContext)
  const tasksIds = useMemo(() => {
    return tasks?.map((task: any) => task.ticketID)
  }, [tasks])

  const {setNodeRef} = useSortable({
    id: column.columnID,
    data: {
      type: 'Column',
      column,
    },
  })

  const columnData = column

  return (
    <div
      ref={setNodeRef}
      className='column-content-container board-column-item min-w-300px w-300px'
    >
      <div className='board-card-wrapper'>
        <SortableContext items={tasksIds}>
          {tasks.map((task: any) => (
            <TaskCard key={task.ticketID} task={task} />
          ))}
        </SortableContext>
        {hasPermission && (
          <>
            {isCreateCardFromColumn ? (
              <div
                onClick={() => handleAddCardFromColumn({columnData})}
                className='board-task-create-link text-muted text-hover-primary cursor-pointer border-gray-300 border-2 d-flex border rounded-1 align-items-center px-4 py-3'
              >
                <i className='fa-solid fa-plus me-3'></i>
                <span className=''>Create Task</span>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ColumnContainer
