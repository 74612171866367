import {
  $createCodeNode,
  $isCodeNode,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  CODE_LANGUAGE_MAP,
  getLanguageFriendlyName,
} from '@lexical/code'
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link'
import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import {$isDecoratorBlockNode} from '@lexical/react/LexicalDecoratorBlockNode'
import {INSERT_HORIZONTAL_RULE_COMMAND} from '@lexical/react/LexicalHorizontalRuleNode'
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode,
  HeadingTagType,
} from '@lexical/rich-text'
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection'
import {$isTableNode} from '@lexical/table'
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  IS_APPLE,
  mergeRegister,
} from '@lexical/utils'
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  NodeKey,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'
import {Dispatch, useCallback, useEffect, useState} from 'react'

import {InsertImageDialog} from './ImagesPlugin'
import {InsertInlineImageDialog} from './InlineImagePlugin'
import {InsertTableDialog} from './TablePlugin'
import InsertLayoutDialog from './LayoutPlugin/InsertLayoutDialog'
import {INSERT_COLLAPSIBLE_COMMAND} from './CollapsiblePlugin'
import {getSelectedNode} from '../utils/getSelectedNode'
import {sanitizeUrl} from '../utils/url'
import DropDown, {DropDownItem} from '../components/DropDown'
import DropdownColorPicker from '../components/DropdownColorPicker'
import useModal from '../hooks/useModal'
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {convertHTMLToLexical, convertLexicalToHTML} from '../utils/common'
import {$convertFromMarkdownString, $convertToMarkdownString} from '@lexical/markdown'
import {PLAYGROUND_TRANSFORMERS} from './MarkdownTransformers'

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
}

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
}

function getCodeLanguageOptions(): [string, string][] {
  const options: [string, string][] = []

  for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
    options.push([lang, friendlyName])
  }

  return options
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions()

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['Arial', 'Arial'],
  ['Courier New', 'Courier New'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana'],
]

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['8px', '8px'],
  ['9px', '9px'],
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['14px', '14px'],
  ['16px', '16px'],
  ['18px', '18px'],
  ['20px', '20px'],
  ['22px', '22px'],
  ['24px', '24px'],
  ['26px', '26px'],
  ['28px', '28px'],
  ['36px', '36px'],
  ['48px', '48px'],
  ['72px', '72px'],
]

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, ''>]: {
    icon: string
    iconRTL: string
  }
} = {
  center: {
    icon: 'center-align',
    iconRTL: 'center-align',
  },
  end: {
    icon: 'right-align',
    iconRTL: 'left-align',
  },
  justify: {
    icon: 'justify-align',
    iconRTL: 'justify-align',
  },
  left: {
    icon: 'left-align',
    iconRTL: 'left-align',
  },
  right: {
    icon: 'right-align',
    iconRTL: 'left-align',
  },
  start: {
    icon: 'left-align',
    iconRTL: 'right-align',
  },
}

function dropDownActiveClass(active: boolean) {
  if (active) return 'active dropdown-item-active'
  else return ''
}

function BlockFormatDropDown({
  editor,
  blockType,
  rootType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName
  rootType: keyof typeof rootTypeToRootName
  editor: LexicalEditor
  disabled?: boolean
}): JSX.Element {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      // if ($isNodeSelection(selection)) {
      $setBlocksType(selection, () => $createParagraphNode())
      // }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        // if ($isSelectionCapturedInDecorator(selection)) {
        $setBlocksType(selection, () => $createHeadingNode(headingSize))
        // }
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection()
        // if ($isNodeSelection(selection)) {
        $setBlocksType(selection, () => $createQuoteNode())
        // }
      })
    }
  }

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection: any = $getSelection()

        // if ($isNodeSelection(selection)) {
        if (selection.isCollapsed()) {
          $setBlocksType(selection, () => $createCodeNode())
        } else {
          const textContent = selection.getTextContent()
          const codeNode = $createCodeNode()
          selection.insertNodes([codeNode])
          selection = $getSelection()
          if ($isRangeSelection(selection)) selection.insertRawText(textContent)
        }
        // }
      })
    }
  }

  return (
    <DropDown
      disabled={disabled}
      buttonClassName='toolbar-item block-controls'
      buttonIconClassName={'icon block-type ' + blockType}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel='Formatting options for text style'
    >
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'paragraph')}
        onClick={formatParagraph}
      >
        <i className='icon paragraph' />
        <span className='text'>Normal</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h1')}
        onClick={() => formatHeading('h1')}
      >
        <i className='icon h1' />
        <span className='text'>Heading 1</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h2')}
        onClick={() => formatHeading('h2')}
      >
        <i className='icon h2' />
        <span className='text'>Heading 2</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h3')}
        onClick={() => formatHeading('h3')}
      >
        <i className='icon h3' />
        <span className='text'>Heading 3</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h4')}
        onClick={() => formatHeading('h4')}
      >
        <i className='icon h4' />
        <span className='text'>Heading 4</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h5')}
        onClick={() => formatHeading('h5')}
      >
        <i className='icon h5' />
        <span className='text'>Heading 5</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h6')}
        onClick={() => formatHeading('h6')}
      >
        <i className='icon h6' />
        <span className='text'>Heading 6</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'bullet')}
        onClick={formatBulletList}
      >
        <i className='icon bullet-list' />
        <span className='text'>Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'number')}
        onClick={formatNumberedList}
      >
        <i className='icon numbered-list' />
        <span className='text'>Numbered List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'check')}
        onClick={formatCheckList}
      >
        <i className='icon check-list' />
        <span className='text'>Check List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'quote')}
        onClick={formatQuote}
      >
        <i className='icon quote' />
        <span className='text'>Quote</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'code')}
        onClick={formatCode}
      >
        <i className='icon code' />
        <span className='text'>Code Block</span>
      </DropDownItem>
    </DropDown>
  )
}

function Divider(): JSX.Element {
  return <div className='divider' />
}

function FontDropDown({
  editor,
  value,
  style,
  disabled = false,
}: {
  editor: LexicalEditor
  value: string
  style: string
  disabled?: boolean
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection: any = $getSelection()
        // if ($isNodeSelection(selection)) {
        $patchStyleText(selection, {
          [style]: option,
        })
        // }
      })
    },
    [editor, style]
  )

  const buttonAriaLabel =
    style === 'font-family'
      ? 'Formatting options for font family'
      : 'Formatting options for font size'

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={'toolbar-item ' + style}
      buttonLabel={value}
      buttonIconClassName={style === 'font-family' ? 'icon block-type font-family' : ''}
      buttonAriaLabel={buttonAriaLabel}
    >
      {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(([option, text]) => (
        <DropDownItem
          className={`item ${dropDownActiveClass(value === option)} ${
            style === 'font-size' ? 'fontsize-item' : ''
          }`}
          onClick={() => handleClick(option)}
          key={option}
        >
          <span className='text'>{text}</span>
        </DropDownItem>
      ))}
    </DropDown>
  )
}

function ElementFormatDropdown({
  editor,
  value,
  isRTL,
  disabled = false,
}: {
  editor: LexicalEditor
  value: ElementFormatType
  isRTL: boolean
  disabled: boolean
}) {
  const formatOption = ELEMENT_FORMAT_OPTIONS[value || 'left']

  return (
    <DropDown
      disabled={disabled}
      buttonIconClassName={`icon ${isRTL ? formatOption.iconRTL : formatOption.icon}`}
      buttonClassName='toolbar-item spaced alignment'
      buttonAriaLabel='Formatting options for text alignment'
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        }}
        className='item'
      >
        <i className='icon left-align' />
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        }}
        className='item'
      >
        <i className='icon center-align' />
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        }}
        className='item'
      >
        <i className='icon right-align' />
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        }}
        className='item'
      >
        <i className='icon justify-align' />
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'start')
        }}
        className='item'
      >
        <i
          className={`icon ${
            isRTL ? ELEMENT_FORMAT_OPTIONS.start.iconRTL : ELEMENT_FORMAT_OPTIONS.start.icon
          }`}
        />
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'end')
        }}
        className='item'
      >
        <i
          className={`icon ${
            isRTL ? ELEMENT_FORMAT_OPTIONS.end.iconRTL : ELEMENT_FORMAT_OPTIONS.end.icon
          }`}
        />
      </DropDownItem>
      <Divider />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
        }}
        className='item'
      >
        <i className={'icon ' + (isRTL ? 'indent' : 'outdent')} />
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
        }}
        className='item'
      >
        <i className={'icon ' + (isRTL ? 'outdent' : 'indent')} />
      </DropDownItem>
    </DropDown>
  )
}

export default function ToolbarPlugin({
  setIsLinkEditMode,
  disabled = false,
}: {
  setIsLinkEditMode: Dispatch<boolean>
  disabled?: boolean
}): JSX.Element {
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(editor)
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph')
  const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>('root')
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null)
  const [fontSize, setFontSize] = useState<string>('16px')
  const [fontColor, setFontColor] = useState<string>('#000')
  const [bgColor, setBgColor] = useState<string>('#fff')
  const [fontFamily, setFontFamily] = useState<string>('Arial')
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left')
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isSubscript, setIsSubscript] = useState(false)
  const [isSuperscript, setIsSuperscript] = useState(false)
  const [isCode, setIsCode] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [modal, showModal] = useModal()
  const [isRTL, setIsRTL] = useState(false)
  const [codeLanguage, setCodeLanguage] = useState<string>('')
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())
  const [isHtmlConverterActive, setIsHtmlConverterActive] = useState(false)

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      setIsSubscript(selection.hasFormat('subscript'))
      setIsSuperscript(selection.hasFormat('superscript'))
      setIsCode(selection.hasFormat('code'))
      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      const tableNode = $findMatchingParent(node, $isTableNode)
      if ($isTableNode(tableNode)) {
        setRootType('table')
      } else {
        setRootType('root')
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName)
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP
            setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '')
            return
          }
        }
      }
      // Handle buttons
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '16px'))
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'))
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'))
      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'))
      let matchingParent
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        )
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || 'left'
      )
    }
  }, [activeEditor])

  useEffect(() => {
    if (disabled) {
      editor.setEditable(false)
    } else {
      editor.setEditable(true)
    }
  }, [editor, disabled])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar()
        setActiveEditor(newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL
    )
  }, [editor, $updateToolbar, disabled])

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable)
      }),
      activeEditor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [$updateToolbar, activeEditor, editor])

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload
        const {code, ctrlKey, metaKey} = event

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault()
          if (!isLink) {
            setIsLinkEditMode(true)
          } else {
            setIsLinkEditMode(false)
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
        }
        return false
      },
      COMMAND_PRIORITY_NORMAL
    )
  }, [activeEditor, isLink, setIsLinkEditMode])

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection: any = $getSelection()
        // if ($isNodeSelection(selection)) {
        $patchStyleText(selection, styles)
        // }
      })
    },
    [activeEditor]
  )

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const nodes = selection.getNodes()

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return
        }

        nodes.forEach((node: any, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            if (idx === 0 && anchor.offset !== 0) {
              node = node.splitText(anchor.offset)[1] || node
            }
            if (idx === nodes.length - 1) {
              node = node.splitText(focus.offset)[0] || node
            }

            if (node.__style !== '') {
              node.setStyle('')
            }
            if (node.__format !== 0) {
              node.setFormat(0)
              $getNearestBlockElementAncestorOrThrow(node).setFormat('')
            }
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true)
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('')
          }
        })
      }
    })
  }, [activeEditor])

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({color: value})
    },
    [applyStyleText]
  )

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({'background-color': value})
    },
    [applyStyleText]
  )

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey)
          if ($isCodeNode(node)) {
            node.setLanguage(value)
          }
        }
      })
    },
    [activeEditor, selectedElementKey]
  )

  const handleHTMLToggle = useCallback(() => {
    editor.update(
      () => {
        const root = $getRoot()
        const firstChild = root.getFirstChild()

        if ($isCodeNode(firstChild) && firstChild.getLanguage() === 'html') {
          // Convert HTML string to Lexical nodes
          root.clear()
          const htmlContent = firstChild.getTextContent()
          convertHTMLToLexical(editor, htmlContent)
        } else {
          // Convert Lexical nodes to HTML string
          const html = convertLexicalToHTML(editor)
          root.clear().append($createCodeNode('html').append($createTextNode(html)))
        }
        root.selectEnd()
      },
      {
        discrete: true,
      }
    )
  }, [editor])

  const handleMarkdownToggle = useCallback(() => {
    editor.update(() => {
      const root = $getRoot()
      const firstChild = root.getFirstChild()
      if ($isCodeNode(firstChild) && firstChild.getLanguage() === 'markdown') {
        $convertFromMarkdownString(firstChild.getTextContent(), PLAYGROUND_TRANSFORMERS)
      } else {
        const markdown = $convertToMarkdownString(PLAYGROUND_TRANSFORMERS)
        root.clear().append($createCodeNode('markdown').append($createTextNode(markdown)))
      }
      root.selectEnd()
    })
  }, [editor])

  return (
    <div className='toolbar'>
      <button
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined)
        }}
        title={IS_APPLE ? 'Undo (⌘Z)' : 'Undo (Ctrl+Z)'}
        type='button'
        className='toolbar-item spaced'
        aria-label='Undo'
      >
        <i className='format undo' />
      </button>
      <button
        disabled={!canRedo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined)
        }}
        title={IS_APPLE ? 'Redo (⌘Y)' : 'Redo (Ctrl+Y)'}
        type='button'
        className='toolbar-item'
        aria-label='Redo'
      >
        <i className='format redo' />
      </button>
      <Divider />
      <button
        disabled={!isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
        type='button'
        aria-label={`Format text as bold. Shortcut: ${IS_APPLE ? '⌘B' : 'Ctrl+B'}`}
      >
        <i className='format bold' />
      </button>
      <button
        disabled={!isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
        type='button'
        aria-label={`Format text as italics. Shortcut: ${IS_APPLE ? '⌘I' : 'Ctrl+I'}`}
      >
        <i className='format italic' />
      </button>
      <button
        disabled={!isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        title={IS_APPLE ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}
        type='button'
        aria-label={`Format text to underlined. Shortcut: ${IS_APPLE ? '⌘U' : 'Ctrl+U'}`}
      >
        <i className='format underline' />
      </button>
      <button
        disabled={!isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
        className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
        title='Strikethrough'
        type='button'
        aria-label='Format text with a strikethrough'
      >
        <i className='format strikethrough' />
      </button>
      <Divider />
      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown
            disabled={!isEditable}
            blockType={blockType}
            rootType={rootType}
            editor={editor}
          />
          <Divider />
        </>
      )}
      {blockType === 'code' ? (
        <>
          <DropDown
            disabled={!isEditable}
            buttonClassName='toolbar-item code-language'
            buttonLabel={getLanguageFriendlyName(codeLanguage)}
            buttonAriaLabel='Select language'
          >
            {CODE_LANGUAGE_OPTIONS.map(([value, name]) => {
              return (
                <DropDownItem
                  className={`item ${dropDownActiveClass(value === codeLanguage)}`}
                  onClick={() => onCodeLanguageSelect(value)}
                  key={value}
                >
                  <span className='text'>{name}</span>
                </DropDownItem>
              )
            })}
          </DropDown>

          {codeLanguage === 'html' && (
            <button
              disabled={!isEditable}
              onClick={() => {
                setIsHtmlConverterActive(!isHtmlConverterActive)
                handleHTMLToggle()
              }}
              className={'toolbar-item spaced'}
              type='button'
              title='Convert HTML'
              aria-label='Convert HTML to editor format'
            >
              <i
                className={
                  `fa-brands fa-html5 fs-1 ` +
                  (isHtmlConverterActive ? 'text-gray-800' : 'text-gray-500')
                }
              ></i>
            </button>
          )}
          {codeLanguage === 'markdown' && (
            <button
              disabled={!isEditable}
              onClick={handleMarkdownToggle}
              className={'toolbar-item spaced'}
              type='button'
              title='Convert Markdown'
              aria-label='Convert Markdown'
            >
              <i className='icon markdown' />
            </button>
          )}
        </>
      ) : (
        <>
          <FontDropDown
            disabled={!isEditable}
            //eslint-disable-next-line
            style={'font-size'}
            value={fontSize}
            editor={editor}
          />
          <FontDropDown
            disabled={!isEditable}
            //eslint-disable-next-line
            style={'font-family'}
            value={fontFamily}
            editor={editor}
          />
          <ElementFormatDropdown
            disabled={!isEditable}
            value={elementFormat}
            editor={editor}
            isRTL={isRTL}
          />

          <Divider />

          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')
            }}
            className={'toolbar-item spaced ' + (isCode ? 'active' : '')}
            title='Insert code block'
            type='button'
            aria-label='Insert code block'
          >
            <i className='format code' />
          </button>
          <button
            disabled={!isEditable}
            onClick={insertLink}
            className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
            aria-label='Insert link'
            title='Insert link'
            type='button'
          >
            <i className='format link' />
          </button>
          <DropdownColorPicker
            disabled={!isEditable}
            buttonClassName='toolbar-item color-picker'
            buttonAriaLabel='Formatting text color'
            buttonIconClassName='icon font-color'
            color={fontColor}
            onChange={onFontColorSelect}
            title='text color'
          />
          <DropdownColorPicker
            disabled={!isEditable}
            buttonClassName='toolbar-item color-picker'
            buttonAriaLabel='Formatting background color'
            buttonIconClassName='icon bg-color'
            color={bgColor}
            onChange={onBgColorSelect}
            title='bg color'
          />

          <DropDown
            disabled={!isEditable}
            buttonClassName='toolbar-item spaced'
            buttonLabel=''
            buttonAriaLabel='Formatting options for additional text styles'
            buttonIconClassName='icon dropdown-more'
          >
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
              }}
              className={'item ' + dropDownActiveClass(isStrikethrough)}
              title='Strikethrough'
              aria-label='Format text with a strikethrough'
            >
              <i className='icon strikethrough' />
              <span className='text'>Strikethrough</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript')
              }}
              className={'item ' + dropDownActiveClass(isSubscript)}
              title='Subscript'
              aria-label='Format text with a subscript'
            >
              <i className='icon subscript' />
              <span className='text'>Subscript</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript')
              }}
              className={'item ' + dropDownActiveClass(isSuperscript)}
              title='Superscript'
              aria-label='Format text with a superscript'
            >
              <i className='icon superscript' />
              <span className='text'>Superscript</span>
            </DropDownItem>
            <DropDownItem
              onClick={clearFormatting}
              className='item'
              title='Clear text formatting'
              aria-label='Clear all text formatting'
            >
              <i className='icon clear' />
              <span className='text'>Clear Formatting</span>
            </DropDownItem>
          </DropDown>
          <Divider />
          <DropDown
            disabled={!isEditable}
            buttonClassName='toolbar-item spaced'
            buttonLabel='Insert'
            buttonAriaLabel='Insert specialized editor node'
            buttonIconClassName='icon plus'
          >
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)
              }}
              className='item'
            >
              <i className='icon horizontal-rule' />
              <span className='text'>Horizontal Rule</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                showModal('Insert Image', (onClose: any) => (
                  <InsertImageDialog activeEditor={activeEditor} onClose={onClose} />
                ))
              }}
              className='item'
            >
              <i className='icon image' />
              <span className='text'>Image</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                showModal('Insert Inline Image', (onClose: any) => (
                  <InsertInlineImageDialog activeEditor={activeEditor} onClose={onClose} />
                ))
              }}
              className='item'
            >
              <i className='icon image' />
              <span className='text'>Inline Image</span>
            </DropDownItem>

            <DropDownItem
              onClick={() => {
                showModal('Insert Table', (onClose: any) => (
                  <InsertTableDialog activeEditor={activeEditor} onClose={onClose} />
                ))
              }}
              className='item'
            >
              <i className='icon table' />
              <span className='text'>Table</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                showModal('Insert Columns Layout', (onClose: any) => (
                  <InsertLayoutDialog activeEditor={activeEditor} onClose={onClose} />
                ))
              }}
              className='item'
            >
              <i className='icon columns' />
              <span className='text'>Columns Layout</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined)
              }}
              className='item'
            >
              <i className='icon caret-right' />
              <span className='text'>Collapsible container</span>
            </DropDownItem>
          </DropDown>
          <button
            disabled={!isEditable}
            onClick={() => {
              setIsHtmlConverterActive(!isHtmlConverterActive)
              handleHTMLToggle()
            }}
            className={'toolbar-item'}
            type='button'
            title='Convert HTML'
            aria-label='Convert HTML to editor format'
          >
            <span
              className={
                `icon fa-brands fa-html5 fs-1 d-flex justify-content-center align-items-center ` +
                (isHtmlConverterActive ? 'text-gray-800' : 'text-gray-500')
              }
            ></span>
          </button>
          <button
            disabled={!isEditable}
            onClick={handleMarkdownToggle}
            className={'toolbar-item'}
            type='button'
            title='Convert Markdown'
            aria-label='Convert Markdown'
          >
            <i className='icon markdown' />
          </button>
        </>
      )}
      <Divider />
      {modal}
    </div>
  )
}
