import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../_metronic/helpers'
import {useEffect} from 'react'

export interface ConfirmationModalDialog {
  uniqueID?: string | number
  show: boolean
  onClose: () => void
  onAction?: any
  isOperationLoading?: boolean
  isDataLoading?: boolean
  body?: any
  actionName?: string
  actionBtnClass?: string
  title?: any
  bodyClass?: string
  dialogClassName?: string
  disableAction?: boolean
  okayBtnClass?: string
  modalClass?: string
  isDisabled?: boolean
  disableFooter?: boolean
}

const ConfirmationModal = ({
  uniqueID,
  show,
  onClose,
  onAction,
  isOperationLoading,
  isDataLoading = false,
  body,
  actionName,
  actionBtnClass,
  title,
  bodyClass,
  disableAction,
  okayBtnClass,
  modalClass = '',
  dialogClassName = '',
  isDisabled = false,
  disableFooter = false,
}: ConfirmationModalDialog) => {
  useEffect(() => {
    if (isDataLoading) {
      onClose()
    }
    //eslint-disable-next-line
  }, [isDataLoading])

  if (!show) {
    return null
  }

  const onClickAction = () => {
    if (uniqueID && onAction) {
      onAction(uniqueID)
    } else if (!uniqueID && onAction) {
      onAction()
    }
  }

  return (
    <Modal
      className={`modal ${modalClass} fade`}
      id='kt_modal'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName={dialogClassName ? dialogClassName : 'modal-l'}
      onHide={onClose}
      backdrop='static'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{title ? title : 'Are you sure?'}</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className={`modal-body ${bodyClass ? bodyClass : ''}`}>
          {body ? (
            body
          ) : (
            <>
              <p className='mb-2'>Do you really want to delete this record?</p>
              <p>This action is irreversible.</p>
            </>
          )}
        </div>

        {!disableFooter && (
          <div className='modal-footer'>
            {!disableAction && (
              <button
                type='button'
                className='btn btn-outline btn-active-light-primary'
                onClick={onClose}
              >
                {onAction ? 'Cancel' : 'Close'}
              </button>
            )}
            {onAction &&
              (!isOperationLoading ? (
                <>
                  {disableAction ? (
                    <button
                      type='button'
                      className={`btn ${okayBtnClass ? okayBtnClass : 'btn-danger'}`}
                      onClick={onClose}
                    >
                      Okay
                    </button>
                  ) : (
                    <button
                      type='submit'
                      className={`btn ${actionBtnClass ? actionBtnClass : 'btn-danger'}`}
                      onClick={onClickAction}
                      disabled={isDisabled}
                    >
                      <span className='indicator-label'>{actionName ? actionName : 'Yes'}</span>{' '}
                    </button>
                  )}
                </>
              ) : (
                <button
                  className={`btn ${actionBtnClass ? actionBtnClass : 'btn-danger'}`}
                  disabled={isDisabled}
                >
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ConfirmationModal
