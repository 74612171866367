import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

const useGetTicketDetails = () => {
  const Api = useApi()
  const {projectId, cardId} = useParams<any>()
  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${projectId}/cards/${cardId}`, {
      queryId: 'project-ticket-details'
    }
  )

  return {
    ticketDetails: response?.data?.[0] || [],
    isLoading: isFetching,
  }
}

export default useGetTicketDetails
