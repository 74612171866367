import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import ProjectMemberActionDropdown from '../actionsDropdowns/ProjectMemberActionDropdown'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectsMembersPageContext} from '../../context'

const ProjectMemberActions = ({memberDetails}: any) => {
  const {
    removeGlobalProjectMember,
    isLoadingGlobalProjectsMembersOperation,
    isLoadingGlobalProjectsMembersList,
  } = useContext(ProjectsMembersPageContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const onRemove = async (memberId: any) => {
    await removeGlobalProjectMember(memberId)
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={<ProjectMemberActionDropdown setShowDeleteModal={setShowDeleteModal} />}
      />

      {showDeleteModal && (
        <>
          <ConfirmationModal
            uniqueID={memberDetails?.username}
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onAction={onRemove}
            isOperationLoading={isLoadingGlobalProjectsMembersOperation}
            isDataLoading={isLoadingGlobalProjectsMembersList}
            body={
              <p>
                Do you really want to remove this member? <br></br>This action is irreversible.
              </p>
            }
          />
        </>
      )}
    </>
  )
}

export default ProjectMemberActions
