import {useContext} from 'react'
import Loading from '../../../../loading'
import DndTable from '../../../../../components/dndTable/DndTable'
import {ProjectsCustomFieldsPageContext} from '../../../context'
import CustomFieldActionsOverlay from '../../actionsDropdowns/CustomFieldActionsOverlay'
import usePermission from '../../../../../hook/usePermission'

export default function CustomFieldsTable() {
  const {hasPermission} = usePermission()
  const {
    updateGlobalCustomFieldTableSorting,
    globalCustomFieldsList,
    isLoadingGlobalCustomFieldList,
  } = useContext(ProjectsCustomFieldsPageContext)

  const handleUpdatedTableRows = (tableData: any) => {
    const customFieldsTableRowOrder = {
      custom_field_ids: tableData.map((item: any) => item.id),
    }
    updateGlobalCustomFieldTableSorting(customFieldsTableRowOrder)
  }

  const formattedData = globalCustomFieldsList?.map((cField: any) => {
    const tableData = [
      {
        columnName: 'NAME',
        headerClass: 'min-w-180px',
        columnData: cField.custom_field_name,
      },
      {
        columnName: 'FIELD TYPE',
        headerClass: 'w-150px',
        columnData: cField.field_type,
      },
      {
        columnName: 'USED IN PROJECTS',
        headerClass: 'w-200px',
        columnData: cField.used_in_projects,
      },
    ]

    if (hasPermission('projects_settings', 'write')) {
      tableData.push({
        columnName: 'ACTION',
        headerClass: 'w-80px text-center',
        columnData: (
          <CustomFieldActionsOverlay
            customFieldId={cField.custom_field_id}
            customFieldData={cField}
          />
        ),
      })
    }

    return {
      id: cField.custom_field_id,
      tableData: tableData,
    }
  })

  return (
    <div className='position-relative'>
      {isLoadingGlobalCustomFieldList && <Loading />}
      <DndTable
        data={formattedData}
        isLoading={isLoadingGlobalCustomFieldList}
        handleUpdatedTableRows={handleUpdatedTableRows}
        isDraggable={hasPermission('projects_settings', 'write')}
      />
    </div>
  )
}
