import {useContext} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {ProjectsListPageContext} from '../../context'
import {getModifiedInputFields} from '../../utils/getModifiedInputFields'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectsDetailsModalTypes} from '../../interfaces'
import {SectionForm} from '../../../../components/SectionForm'
import {createProjectSchema} from '../../validations'
import {defaultProjectCreateData} from '../../parsings'

const ProjectsDetailsModal = ({
  show,
  onClose,
  modalTitle,
  projectDetails = null,
  isEdit,
}: ProjectsDetailsModalTypes) => {
  const {
    handleSubmit,
    register,
    setError,
    formState: {isValid, errors, isDirty},
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(createProjectSchema),
    defaultValues: isEdit
      ? {
          name: projectDetails.name,
          description: projectDetails.description,
          buId: projectDetails.buId,
        }
      : defaultProjectCreateData,
  })

  const {
    isLoading,
    isOperationLoading,
    updateProjectDetails,
    createNewProjectDetails,
    businessUnitsList,
  } = useContext(ProjectsListPageContext)

  const handleUpdateDetails = (data: any) => {
    const createPayload = {
      ...data,
      isArchived: false,
      boardId: 1,
    }
    if (isEdit) {
      const modifiedFields = getModifiedInputFields(projectDetails, data)
      updateProjectDetails(modifiedFields, projectDetails?.id, setError)
    } else {
      createNewProjectDetails(createPayload, setError)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={modalTitle}
      onAction={handleSubmit(handleUpdateDetails)}
      actionName={projectDetails ? 'Update' : 'Create'}
      actionBtnClass='btn-primary'
      isDisabled={!isValid || !isDirty}
      isOperationLoading={isOperationLoading}
      isDataLoading={isLoading}
      body={
        <SectionForm
          id='create-project-form'
          register={register}
          errors={errors}
          inputs={[
            {
              id: 'project-name',
              label: 'Project Name',
              class: 'mb-10',
              required: true,
              registerKey: 'name',
              placeholder: 'Enter Project Name',
              maxLength: 128,
            },
            {
              id: 'description',
              label: 'Description',
              class: 'mb-10',
              isTextArea: true,
              registerKey: 'description',
              placeholder: 'Enter Description',
              inputClass: 'resize-none',
              rows: 4,
              maxLength: 255,
            },
            {
              id: 'business-unit',
              label: 'Business Unit',
              class: 'mb-10',
              isSelect: true,
              required: true,
              placeholderValue: 0,
              registerKey: 'buId',
              placeholder: 'Select business unit',
              options: businessUnitsList?.map((businessUnit: any) => ({
                label: businessUnit?.business_unit_name,
                value: businessUnit?.id,
              })),
              isNullable: true,
            },
          ]}
        />
      }
    />
  )
}

export default ProjectsDetailsModal
