import {createContext} from 'react'

export const DndBoardPageContext = createContext({
  handleTicketLayout: undefined as any,
  isCreateCardFromColumn: undefined as any,
  handleAddCardFromColumn: undefined as any,
  hasPermission: true,
  isDraggable: true,
  dimensions: {} as any,
  setDimensions: (updatedState: any) => {},
})
