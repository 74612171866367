import {createContext} from 'react'

export const ProjectsMainPageContext = createContext({
  projectStatus: '',
  projectName: '',
  projectId: '',
})

export const AgileBoardPageContext = createContext({
  hasBoardPermission: false,

  agileBoardStructure: [],
  isLoadingBoardStructure: false,

  agileBoardData: [],
  isLoadingAgileBoardData: false,

  projectMembers: [],
  isLoadingProjectMembers: false,

  projectKanbanStages: [],
  isLoadingProjectKanbanStages: false,

  projectModules: [],
  isLoadingProjectModules: false,

  projectStatuses: [],
  isLoadingProjectStatuses: false,

  projectPriorities: [],
  isLoadingProjectPriorities: false,

  createTicket: (moduleId: string, data: any) => {},
  isLoadingTicketOperation: false,

  updateAgileBoard: (updatedTaskDetails: any) => {},
  isUpdatingAgileBoard: false,
})

export const ProjectMembersPageContext = createContext({
  projectMembersList: [],
  isLoadingProjectMembersList: false,
  filters: {},
  onSearch: (e: string) => {},

  membersList: [],
  isLoadingMembersList: false,

  addProjectMembers: (memberDetails: any) => {},
  removeProjectMember: (memberID: number) => {},
  isLoadingOperation: false,

  hasMembersPermission: false,
})

export const BoardSettingsPageContext = createContext({
  createColumn: (data: any) => {},
  updateColumn: (moduleId: string, data: any) => {},
  updateColumnTableSorting: (columnTableRowOrder: any) => {},
  deleteColumn: (moduleId: string) => {},
  isLoadingColumnOperation: false,

  createCustomField: (customFieldDetails: any) => {},
  updateCustomField: (cFieldId: string, customFieldDetails: any) => {},
  updateCustomFieldTableSorting: (customFieldsTableRowOrder: any) => {},
  deleteCustomField: (cFieldId: string) => {},
  isLoadingCustomFieldOperation: false,

  boardColumnsList: [],
  isLoadingBoardColumnsList: false,

  boardCustomFieldsList: [],
  isLoadingBoardCustomFieldsList: false,

  boardModulesList: [],
  isLoadingBoardModulesList: false,

  createModule: (data: any) => {},
  updateModule: (moduleId: string, data: any) => {},
  updateModuleTableSorting: (moduleTableRowOrder: any) => {},
  deleteModule: (moduleId: string) => {},
  isLoadingModulesOperation: false,

  hasBoardSettingsPermission: false,
})

export const TicketDetailsPageContext = createContext({
  hasTicketWritePermission: false,

  projectStatus: '',
  projectName: '',
  projectId: '',
  moduleId: '',
  cardId: '',

  ticketDetails: {} as any,
  isLoadingTicketDetails: false,
  refetchTicketDetails: () => {},

  projectMembers: [],
  isLoadingProjectMembers: false,

  projectKanbanStages: [],
  isLoadingProjectKanbanStages: false,

  projectModules: [],
  isLoadingProjectModules: false,

  projectStatuses: [],
  isLoadingProjectStatuses: false,

  projectPriorities: [],
  isLoadingProjectPriorities: false,

  updateTicket: (data: any) => {},
  isLoadingTicketOperation: false,

  createComment: (commentData: any) => {},
  editComment: (commentId: any, data: any) => {},
  deleteComment: (commentId: any) => {},
  isLoadingCommentOperation: false,

  additionalInfoCFieldsList: [],
  isLoadingAdditionalInfoCFieldsList: false,
  refetchAdditionalInfoCFieldsList: () => {},

  commentsListData: [],
  isLoadingCommentsListData: false,
  refetchCommentsListData: () => {},

  editTicketCFieldValue: (ticketCFieldData: any) => {},
  isLoadingTicketCFieldOperation: false,
})
