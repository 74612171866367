import {useParams} from 'react-router-dom'
import {TicketDetailsPageContext} from '../context'
import usePermission from '../../../hook/usePermission'
import useGetTicketDetails from '../hooks/useGetTicketDetails'
import useCommentOperations from '../hooks/useCommentOperations'
import useGetCustomFieldsList from '../hooks/useGetCustomFieldsList'
import useGetCommentsList from '../hooks/useGetCommentsList'
import useCustomFieldsOperations from '../hooks/useCustomFieldsOperations'
import useTicketOperations from '../hooks/useTicketOperations'
import useGetProjectKanbanStages from '../hooks/useGetProjectKanbanStages'
import useGetProjectModules from '../hooks/useGetProjectModules'
import useGetProjectStatuses from '../hooks/useGetProjectStatuses'
import useGetProjectPriorites from '../hooks/useGetProjectPriorites'
import useGetProjectMembers from '../hooks/useGetProjectMembers'
import TicketForm from '../sections/TicketForm'
import {useState} from 'react'

export default function TicketDetails() {
  const {hasPermission} = usePermission()
  const hasTicketWritePermission = hasPermission('projects_all projects', 'write')
  const params = useParams<any>()
  const projectStatus: any = params['projectStatus']
  const projectName: any = params['projectName']
  const projectId: any = params['projectId']
  const moduleId: any = params['moduleId']
  const cardId: any = params['cardId']

  const {ticketDetails, isLoading: isLoadingTicketDetails} = useGetTicketDetails()
  const {
    createComment,
    editComment,
    deleteComment,
    isLoading: isLoadingCommentOperation,
  } = useCommentOperations()
  const {customFieldsList, isLoading: isLoadingCustomFieldsList} = useGetCustomFieldsList()
  const {commentsList, isLoading: isLoadingCommentsList} = useGetCommentsList()
  const {updateCustomField, isLoading: isLoadingCFieldsOperation} = useCustomFieldsOperations()
  const {updateTicket, isLoading: isLoadingTicketOperation} = useTicketOperations()
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()
  const {projectKanbanStages, isLoading: isLoadingProjectKanbanStages} = useGetProjectKanbanStages()
  const {projectModules, isLoading: isLoadingProjectModules} = useGetProjectModules()
  const {projectStatuses, isLoading: isLoadingProjectStatuses} = useGetProjectStatuses()
  const {projectPriorities, isLoading: isLoadingProjectPriorities} = useGetProjectPriorites()
  const [isTicketEditable, setIsTicketEditable] = useState(false)

  const contextValue = {
    hasTicketWritePermission,

    projectStatus,
    projectName,
    projectId,
    moduleId,
    cardId,

    ticketDetails,
    isLoadingTicketDetails,

    projectMembers,
    isLoadingProjectMembers,

    projectKanbanStages,
    isLoadingProjectKanbanStages,

    projectModules,
    isLoadingProjectModules,

    projectStatuses,
    isLoadingProjectStatuses,

    projectPriorities,
    isLoadingProjectPriorities,

    updateTicket,
    isLoadingTicketOperation,

    createComment,
    editComment,
    deleteComment,
    isLoadingCommentOperation,

    customFieldsList,
    isLoadingCustomFieldsList,

    commentsList,
    isLoadingCommentsList,

    updateCustomField,
    isLoadingCFieldsOperation,

    isTicketEditable,
    setIsTicketEditable,
  }

  return (
    <TicketDetailsPageContext.Provider value={contextValue}>
      <TicketForm />
    </TicketDetailsPageContext.Provider>
  )
}
