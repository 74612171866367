import {useContext} from 'react'
import {ProjectsListPageContext} from '../context'
import Search from '../../../components/Search'

const ActiveProjectsSearchComponent = () => {
  const {onSearch} = useContext(ProjectsListPageContext)

  return (
    <div>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
    </div>
  )
}

export {ActiveProjectsSearchComponent}
