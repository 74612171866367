import {useContext, useRef, useState} from 'react'
import {AgileBoardPageContext} from '../../context'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import CreateTicketModal from '../modals/CreateTicketModal'

const AgileBoardPageHeader = () => {
  const handleSubmitRef = useRef<any>(null)
  const [showModal, setShowModal] = useState(false)
  const {
    isUpdatingAgileBoard,
    hasBoardPermission,
    isLoadingProjectKanbanStages,
    isLoadingProjectModules,
    isLoadingTicketOperation,
    isLoadingAgileBoardData,
  } = useContext(AgileBoardPageContext)

  const onCreateTicket = () => {
    if (handleSubmitRef.current) {
      handleSubmitRef.current.submit()
    }
  }

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div>
        {isUpdatingAgileBoard && (
          <button className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'>
            <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
          </button>
        )}
      </div>
      {hasBoardPermission && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Create Issue
        </button>
      )}
      {showModal && (
        <ConfirmationModal
          show={showModal}
          title='Create Ticket'
          onClose={() => setShowModal(false)}
          body={<CreateTicketModal handleSubmitRef={handleSubmitRef} />}
          dialogClassName='modal-xl'
          onAction={onCreateTicket}
          actionName='Create'
          actionBtnClass='btn-primary'
          isDisabled={isLoadingProjectKanbanStages || isLoadingProjectModules}
          isOperationLoading={isLoadingTicketOperation}
          isDataLoading={isLoadingAgileBoardData}
        />
      )}
    </div>
  )
}

export {AgileBoardPageHeader}
