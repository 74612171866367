import usePermission from '../../hook/usePermission'
import Loading from '../../modules/loading'
import useColumnsOperations from './hooks/useColumnsOperations'
import useGetColumnsList from './hooks/useGetColumnsList'
import DndTable from '../dndTable/DndTable'
import {KTSVG} from '../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {isEmptyObj} from '../../utils/common'
import {reorderColumns} from './utils'
import {removeColumn} from '../../modules/inquires/utils'

export default function SideMenu({defaultColumns, permissionPath, refetch, type}: any) {
  const {hasPermission} = usePermission()
  const {
    globalDefaultColumnsList,
    isLoadingGlobalDefaultColumnsList,
    refetchGlobalDefaultColumnsList,
  } = useGetColumnsList()
  const {updateGlobalDefaultColumnsTableSorting, isLoadingGlobalDefaultColumnOperation} =
    useColumnsOperations()
  const [selectedColumns, setSelectedColumns] = useState<any[]>([])
  const [updatedDefaultColumn, setUpdatedDefaultColumn] = useState<any[]>([])

  useEffect(() => {
    if (!isEmptyObj(globalDefaultColumnsList)) {
      const initialSelected = globalDefaultColumnsList?.map((column: any) => ({
        key: Object.keys(column)[0],
        title: Object.values(column)[0],
      }))

      const reorderedColumns = reorderColumns(defaultColumns, globalDefaultColumnsList)
      const reorderedKeys = reorderedColumns.map((column: any) => Object.keys(column)[0])
      sessionStorage.setItem('sortedColumnIds', JSON.stringify(reorderedKeys))
      const updatedColumn = removeColumn(reorderedColumns, 'action')

      setUpdatedDefaultColumn(updatedColumn)

      // Maintain previously selected columns
      const preservedSelectedColumns = selectedColumns.filter((selectedColumn) =>
        initialSelected.some((column: any) => column.key === selectedColumn.key)
      )

      setSelectedColumns(
        preservedSelectedColumns.length ? preservedSelectedColumns : initialSelected
      )
    } else if (defaultColumns) {
      const initialSelected = defaultColumns?.map((column: any) => ({
        key: Object.keys(column)[0],
        title: Object.values(column)[0],
      }))

      const reorderedColumns = reorderColumns(defaultColumns, globalDefaultColumnsList)
      const reorderedKeys = reorderedColumns.map((column: any) => Object.keys(column)[0])
      sessionStorage.setItem('sortedColumnIds', JSON.stringify(reorderedKeys))

      const updatedColumn = removeColumn(reorderedColumns, 'action')

      setUpdatedDefaultColumn(updatedColumn)
      setSelectedColumns(initialSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDefaultColumnsList, defaultColumns])

  const handleCheckboxChange = (key: string, title: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, {key, title}])
    } else {
      setSelectedColumns((prevSelected) => prevSelected.filter((col) => col.key !== key))
    }
  }

  const handleUpdatedShortedTableRows = (tableData: any) => {
    const columnTableRowOrder = tableData.map((item: any) => item.id)
    const reordered = columnTableRowOrder.map((columnId: string) =>
      updatedDefaultColumn.find((column: any) => Object.keys(column)[0] === columnId)
    )
    setUpdatedDefaultColumn(reordered)
    sessionStorage.setItem('sortedColumnIds', JSON.stringify(columnTableRowOrder))
  }

  const formattedData = updatedDefaultColumn.map((column: any) => {
    const key = Object.keys(column)[0]
    const title: any = Object.values(column)[0]

    // Check if the column has "default": true
    const isDefault = column.default === true

    const tableData: any = [
      {
        columnName: 'NAME',
        headerClass: 'min-w-250px',
        columnData: <span className='text-capitalize'>{title}</span>,
      },
    ]

    if (hasPermission(permissionPath, 'write')) {
      tableData.push({
        columnName: 'VISIBILITY',
        headerClass: 'w-80px text-center',
        columnData: (
          <div className='d-flex justify-content-center'>
            <div className='form-check form-check-custom form-check-solid form-check-sm'>
              <input
                type='checkbox'
                name={`visibility_${title}`}
                className='form-check-input'
                checked={selectedColumns.some((col) => col.key === key)}
                onChange={(e) => handleCheckboxChange(key, title, e.target.checked)}
                disabled={isDefault}
              />
            </div>
          </div>
        ),
      })
    }

    return {
      id: key,
      tableData: tableData,
      checkbox: true,
    }
  })

  const handleSave = async () => {
    const savedColumnOrder = JSON.parse(sessionStorage.getItem('sortedColumnIds') || '[]')

    const columnsMap = selectedColumns.reduce((acc, column) => {
      acc[column.key] = column.title.toUpperCase()
      return acc
    }, {})

    const columnsPayload = savedColumnOrder.reduce((acc: any, columnId: any) => {
      const column = updatedDefaultColumn.find((col: any) => Object.keys(col)[0] === columnId)
      if (column && (column.default === true || columnsMap[columnId])) {
        acc.push({[columnId]: columnsMap[columnId]})
      }
      return acc
    }, [])
    const hasActionKey = defaultColumns.some((column: any) => column.hasOwnProperty('action'))
    if (hasActionKey) {
      columnsPayload?.push({action: 'ACTION'})
    }

    const payload = {
      columns: columnsPayload,
      type: type,
    }

    const closeDrawerButton = document.getElementById('kt_drawer_column_setting_close')
    if (closeDrawerButton) {
      closeDrawerButton.click()
    }
    const res: any = await updateGlobalDefaultColumnsTableSorting(payload)

    if (res?.status === 200) {
      refetch()
      refetchGlobalDefaultColumnsList()
    }
  }

  const handleReset = () => {
    const updatedColumn = removeColumn(defaultColumns, 'action')
    const columnTableRowOrder = updatedColumn.map((item: any) => Object.keys(item)[0])
    const initialSelected = updatedColumn?.map((column: any) => ({
      key: Object.keys(column)[0],
      title: Object.values(column)[0],
    }))
    setSelectedColumns(initialSelected)
    setUpdatedDefaultColumn(updatedColumn)
    sessionStorage.setItem('sortedColumnIds', JSON.stringify(columnTableRowOrder))
  }

  return (
    <div
      id='kt_drawer_column_setting'
      className='bg-body drawer drawer-end drawer-on min-w-100px app-drawer-nav-section'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_column_setting_toggle'
      data-kt-drawer-close='#kt_drawer_column_setting_close'
    >
      <div className='px-8'>
        <div className='py-5 border-bottom mb-5 d-flex justify-content-between align-items-center'>
          <h4 className='modal-title fw-bolder'>Settings</h4>
          <div className='' id='kt_drawer_column_setting_close'>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='app-drawer-nav-body' id='kt_drawer_chat_messenger_body'>
          {isLoadingGlobalDefaultColumnsList && <Loading />}
          <div className='d-flex justify-content-between mb-5 align-items-center'>
            <h4 className='mb-0'>Columns</h4>
            <button className='btn btn-outline' onClick={handleReset}>
              Reset
            </button>
          </div>

          <DndTable
            data={formattedData}
            isLoading={isLoadingGlobalDefaultColumnsList}
            handleUpdatedTableRows={handleUpdatedShortedTableRows}
            isDraggable={hasPermission('products_product inquiries', 'write')}
            isUpdateOnChange={true}
          />
        </div>
        <div className='d-flex align-items-center justify-content-end border-top mt-5 py-5'>
          <button type='button' className='btn btn-outline' id='kt_drawer_column_setting_close'>
            Cancel
          </button>
          <button type='button' className='btn btn-primary ms-3' onClick={handleSave}>
            {!isLoadingGlobalDefaultColumnOperation && (
              <span className='indicator-label'>Save</span>
            )}
            {isLoadingGlobalDefaultColumnOperation && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Saving...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
