import {useContext, useState} from 'react'
import ProjectsDetailsModal from '../modals/ProjectsDetailsModal'
import {ProjectsListPageContext} from '../../context'
import usePermission from '../../../../hook/usePermission'
import {useLocation} from 'react-router-dom'
import {ActiveProjectsSearchComponent} from '../../components/ActiveProjectsSearchComponent'

const ActivePageHeader = () => {
  const {hasPermission} = usePermission()
  const [showModal, setShowModal] = useState(false)
  const {isOperationLoading} = useContext(ProjectsListPageContext)
  const isActiveProjects = useLocation().pathname === '/projects/active'

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex align-items-center'>
        <ActiveProjectsSearchComponent />
        {isOperationLoading && (
          <button className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'>
            <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
          </button>
        )}
      </div>
      {hasPermission('projects_all projects', 'write') && isActiveProjects && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Create Projects
        </button>
      )}

      {showModal && isActiveProjects && (
        <ProjectsDetailsModal
          modalTitle={'Create Project'}
          show={showModal}
          onClose={() => setShowModal(false)}
          isEdit={false}
        />
      )}
    </div>
  )
}

export {ActivePageHeader}
