// import './scss/dnd.scss'
import {useEffect, useRef, useState} from 'react'
import ColumnContainer from './components/ColumnContainer'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {createPortal} from 'react-dom'
import TaskCard from './components/TaskCard'
import Loading from '../../modules/loading'
import {capitalizeFirstLetter} from '../../utils/string'
import {handleDragStart} from './utils/handleDragStart'
import {handleDragOver} from './utils/handleDragOver'
import {handleDragEnd} from './utils/handleDragEnd'
import {updateColumnCardsCounter} from './utils/updateColumnCardsCounter'
import {updateModuleCardsCounter} from './utils/updateModuleCardsCounter'
import {mergedAndFormattedApiData} from './utils/mergedAndFormattedApiData'
import {DndBoardPageContext} from './context'
import AgileBoardAccordion from './components/AgileBoardAccordion'
import {calculateTotalWidth} from './utils/calculateTotalWidth'

function DndBoard({
  dndBoardData,
  handleTicketLayout,
  isCreateCardFromColumn,
  handleAddCardFromColumn,
  hasPermission = true,
  isDraggable = true,
}: any) {
  const {
    agileBoardStructure,
    agileBoardData,
    isLoadingBoardStructure,
    isLoadingBoardData,
    updateAgileBoard,
  } = dndBoardData
  const [tasks, setTasks] = useState([])
  const [activeTask, setActiveTask] = useState(null)
  const [isDragging, setIsDragging] = useState(true)
  const [updatedTaskDetails, setUpdatedTaskDetails] = useState<any>()
  const [columnTaskCounts, setColumnTaskCounts] = useState<any>({})
  const [moduleTaskCounts, setModuleTaskCounts] = useState<any>({})
  const [dimensions, setDimensions] = useState<any>({})

  const {mergedData, uniqueModuleNames, uniqueColumnNames, updatedTasksList} =
    mergedAndFormattedApiData(agileBoardStructure, agileBoardData)

  useEffect(() => {
    setTasks(updatedTasksList)
    //eslint-disable-next-line
  }, [agileBoardStructure, agileBoardData])

  useEffect(() => {
    if (!isDragging && updatedTaskDetails) {
      updateAgileBoard(updatedTaskDetails)
    }
    //eslint-disable-next-line
  }, [updatedTaskDetails, isDragging])

  useEffect(() => {
    updateColumnCardsCounter(tasks, mergedData, setColumnTaskCounts)
    updateModuleCardsCounter(tasks, mergedData, setModuleTaskCounts)
    //eslint-disable-next-line
  }, [tasks])

  function onDragStart(event: DragStartEvent) {
    setIsDragging(true)
    handleDragStart(event, mergedData, setUpdatedTaskDetails, setActiveTask)
  }

  function onDragOver(event: DragOverEvent) {
    handleDragOver(event, setTasks)
  }

  function onDragEnd(event: DragEndEvent) {
    setIsDragging(false)
    setActiveTask(null)
    handleDragEnd(event, mergedData, setUpdatedTaskDetails, setTasks)
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0,
      },
    })
  )

  const accordionItems = uniqueModuleNames.map((module: any) => ({
    id: module.moduleID,
    title: (
      <div className='dnd-module-name'>
        {capitalizeFirstLetter(module.moduleName)} (
        {moduleTaskCounts[module.moduleName] ? moduleTaskCounts[module.moduleName] : 0})
      </div>
    ),
    content: (
      <div className='board-column-wrapper d-flex row'>
        {mergedData
          .filter((column: any) => column.moduleName === module.moduleName)
          .map((column: any) => (
            <ColumnContainer
              key={column.columnID}
              column={column}
              tasks={tasks.filter((task: any) => task.columnID === column.columnID)}
            />
          ))}
      </div>
    ),
  }))

  const [totalWidth, setTotalWidth] = useState()
  const dndComponentRef = useRef<any>(null)
  useEffect(() => {
    const calculateAndSetWidth = () => {
      const div = dndComponentRef?.current
      if (div) {
        const width = calculateTotalWidth(div)
        setTotalWidth(width)
      }
    }
    calculateAndSetWidth()
    window.addEventListener('resize', calculateAndSetWidth)
    return () => {
      window.removeEventListener('resize', calculateAndSetWidth)
    }
  }, [isLoadingBoardData, isLoadingBoardStructure])

  return (
    <DndBoardPageContext.Provider
      value={{
        handleTicketLayout,
        isCreateCardFromColumn,
        handleAddCardFromColumn,
        hasPermission,
        isDraggable,
        dimensions,
        setDimensions,
      }}
    >
      <div className='board-main-container overflow-auto w-100 position-relative min-h-350px '>
        {isLoadingBoardStructure || isLoadingBoardData ? (
          <Loading />
        ) : (
          <>
            <div ref={dndComponentRef} className='board-heading-section d-flex row mb-10'>
              {uniqueColumnNames.map((column: any) => (
                <div className='board-heading-item w-300px'>
                  <div className='px-6 py-3 fw-semibold text-dark rounded-1'>
                    {capitalizeFirstLetter(column.columnName)} (
                    {columnTaskCounts[column.columnName] ? columnTaskCounts[column.columnName] : 0})
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{width: totalWidth ? `${totalWidth}px` : 'auto'}}
              className='board-card-section'
            >
              {isDraggable ? (
                <DndContext
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  onDragOver={onDragOver}
                  sensors={sensors}
                >
                  <AgileBoardAccordion items={accordionItems} defaultOpen='All' />

                  {createPortal(
                    <DragOverlay>{activeTask && <TaskCard task={activeTask} />}</DragOverlay>,
                    document.body
                  )}
                </DndContext>
              ) : (
                <div>
                  <AgileBoardAccordion items={accordionItems} defaultOpen='All' />

                  {createPortal(
                    <DragOverlay>{activeTask && <TaskCard task={activeTask} />}</DragOverlay>,
                    document.body
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </DndBoardPageContext.Provider>
  )
}

export default DndBoard
