import {useContext} from 'react'
import {Link} from 'react-router-dom'
import {ProjectsMainPageContext} from '../context'

export const TicketLayout = ({ticketData}: any) => {
  const {projectStatus, projectId, projectName} = useContext(ProjectsMainPageContext)
  const toPath = `/projects/${projectStatus}/${projectName}/${projectId}/module/${ticketData.moduleID}/card/${ticketData.ticketID}`

  return (
    <Link to={toPath} className='d-flex flex-column gap-6 h-100 w-100 text-decoration-none'>
      <div>
        <p className='mb-0 fw-semibold'>
          <span className='text-primary'>{ticketData.card_identifier}</span>
          <span className='text-dark ms-2'>{ticketData.title}</span>
        </p>
      </div>
      {ticketData.asignee_name && (
        <div className='d-flex align-items-center gap-3'>
          <div className='w-35px h-35px rounded-circle bg-primary bg-opacity-15 d-flex justify-content-center align-items-center fw-bold fs-6'>
            {ticketData?.asignee_name
              ?.split(' ')
              ?.slice(0, 2)
              ?.map((word: any) => word[0])
              ?.join('')
              ?.toUpperCase()}
          </div>
          <div className='fs-6 fw-semibold text-dark'>{ticketData.asignee_name}</div>
        </div>
      )}
    </Link>
  )
}
