import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import ProjectsDetailsModal from './ProjectsDetailsModal'
import {useLocation} from 'react-router-dom'
import ProjectArchiveConfirmationModal from './ProjectArchiveConfirmationModal'

const OverlayModal = ({projectDetails}: any) => {
  const isActiveProjects = useLocation().pathname === '/projects/active'
  const [showModal, setShowModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleArchiveproject = () => {
    setShowArchiveModal(true)
    document.body.click()
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <>
            <div className='px-2 py-1'>
              <span className='text-dark fw-bold text-hover-primary fs-6' onClick={handleEditClick}>
                <label>Edit</label>
              </span>
            </div>
            <div className='px-2 py-1'>
              <span
                className={`text-dark fw-bold text-hover-${
                  isActiveProjects ? 'danger' : 'primary'
                } fs-6`}
                onClick={handleArchiveproject}
              >
                <label>{isActiveProjects ? 'Archive' : 'Unarchive'}</label>
              </span>
            </div>
          </>
        }
      />

      {showModal && (
        <ProjectsDetailsModal
          modalTitle={'Edit Project Details'}
          show={showModal}
          onClose={() => setShowModal(false)}
          projectDetails={projectDetails}
          isEdit={true}
        />
      )}

      {showArchiveModal && (
        <ProjectArchiveConfirmationModal
          show={showArchiveModal}
          onClose={() => {
            setShowArchiveModal(false)
          }}
          projectDetails={projectDetails}
        />
      )}
    </>
  )
}

export default OverlayModal
