import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import useToastify from '../hook/useToastify'
import authService from './authServices'
import {dataToSideBarStoreList} from '../utils/common'
import {toSnakeCase} from '../modules/analyticsReplenishment/utils'

interface IAuthentication {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  user: any | null
  message: string | null
  redirect_url: string
  permissions: any | null
}

const initialState: IAuthentication = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  user: null,
  message: '',
  redirect_url: '',
  permissions: null,
}

type userCred = {
  email: string
  password: string
}

// login user
export const login = createAsyncThunk('v2/auth/login', async (user: userCred, thunkAPI) => {
  const {toastMessage} = useToastify()
  try {
    const res = await authService.login(user)
    const stores = dataToSideBarStoreList(res['stores'], res['default_store'])
    const storeNames = stores.map((store: any) => store['name'])
    storeNames.forEach((storeName: any) => {
      const snakeCaseStoreName = toSnakeCase(storeName)
      const supplierKey = `is_supplier_${snakeCaseStoreName}`
      const supplierValue = res?.[supplierKey] || 'false'
      localStorage.setItem(supplierKey, supplierValue)
    })
    if (res?.access_token) {
      toastMessage('success', 'Login successfully')
      localStorage.setItem('x-store-id', res.default_store)
      await thunkAPI.dispatch(
        storePermissions({
          token: res?.access_token,
          storeId: res?.default_store,
          tenantId: res?.tenant_id,
        })
      )
    } else {
      toastMessage('error', 'Verify your user email and password and try again.')
    }

    return res
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// google login function
export const getUserGoogleLogin = createAsyncThunk('auth/glogin', async (data: any, thunkAPI) => {
  const {toastMessage} = useToastify()
  try {
    const res = await authService.googleLogin(data)
    // if (res?.status === 200 && res?.data?.accessToken) {
    if (res?.data?.access_token) {  
      toastMessage('success', 'Login successfully')
      localStorage.setItem('x-store-id', res?.data?.default_store)
       // Dispatch storePermissions and wait for it to complete
       await thunkAPI.dispatch(storePermissions({ token: res?.data?.access_token, storeId: res?.data?.default_store, tenantId: res?.data?.tenant_id }))
    } else {
      toastMessage('error', 'Verify your user email and password and try again.')
    }
    return res?.data
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// logout user
export const logout = createAsyncThunk('auth/logout', async (token: string, thunkAPI) => {
  try {
    return await authService.logOut(token)
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()

    return message
  }
})

export const storePermissions = createAsyncThunk(
  'v2/global/permissions',
  async ({ token, storeId, tenantId }: { token: string; storeId: string; tenantId: string }, thunkAPI) => {
    const { toastMessage } = useToastify();
    try {
      const res = await authService.storePermissions(token, storeId, tenantId);
      return res?.side_menu;
    } catch (error: any) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toastMessage('error', message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    setRedirect: (state, action: any) => {
      return {
        ...state,
        redirect_url: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(getUserGoogleLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserGoogleLogin.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(getUserGoogleLogin.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(storePermissions.fulfilled, (state, action: PayloadAction<any>) => {
        state.permissions = action.payload
      })
      .addCase(storePermissions.rejected, (state, action: PayloadAction<any>) => {
        state.message = action.payload
        state.permissions = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = null
      })
  },
})

export const {reset, setRedirect} = authenticationSlice.actions
export default authenticationSlice.reducer
