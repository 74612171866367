import * as Yup from 'yup'

export const createTicketValidationSchema = Yup.object().shape({
  title: Yup.string().required('Ticket Name is required.'),
})

export const AddBoardColumnSchema = Yup.object().shape({
  column_name: Yup.string().required('Column Name is required'),
  description: Yup.string().required('Description is required'),
})

export const AddBoardModuleSchema = Yup.object().shape({
  module_name: Yup.string().required('Module Name is required'),
})
