import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {SectionForm} from '../../../../components/SectionForm'
import {AgileBoardModalTypes} from '../../interfaces'
import {InputText} from '../../../../components/InputText'
import TextEditor from '../../../../components/newTextEditor/TextEditor'
import {defaultProjectTicketValues, formatCreateTicketPayload} from '../../utils/general'
import {useContext, useImperativeHandle} from 'react'
import {AgileBoardPageContext} from '../../context'
import {createTicketValidationSchema} from '../../validations'

function CreateTicketModal({
  isCreateFromBoard = false,
  columnId = null,
  moduleId = null,
  handleSubmitRef,
}: AgileBoardModalTypes) {
  const {projectMembers, isLoadingProjectMembers} = useContext(AgileBoardPageContext)
  const {projectKanbanStages, isLoadingProjectKanbanStages} = useContext(AgileBoardPageContext)
  const {projectModules, isLoadingProjectModules} = useContext(AgileBoardPageContext)
  const {projectStatuses, isLoadingProjectStatuses} = useContext(AgileBoardPageContext)
  const {projectPriorities, isLoadingProjectPriorities} = useContext(AgileBoardPageContext)
  const {createTicket} = useContext(AgileBoardPageContext)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<any>({
    defaultValues: defaultProjectTicketValues(
      projectKanbanStages,
      projectModules,
      isCreateFromBoard,
      columnId,
      moduleId
    ),
    resolver: yupResolver(createTicketValidationSchema),
  })

  const onCreate = (data: any) => {
    const {payload, moduleId} = formatCreateTicketPayload(
      data,
      defaultProjectTicketValues,
      projectKanbanStages,
      projectModules
    )
    createTicket(moduleId, payload)
  }

  useImperativeHandle(handleSubmitRef, () => ({
    submit: handleSubmit(onCreate),
  }))

  return (
    <>
      <InputText
        id='ticket-title'
        label='Ticket Name'
        className='mb-10'
        isRequired={true}
        register={register('title')}
        placeholder='Enter Ticket Name'
        error={errors?.title}
      />
      <div className='row gx-10'>
        <div className='col-md-8'>
          <TextEditor
            id='description'
            control={control}
            registerKey='description'
            inputClass='min-h-350px'
          />
        </div>
        <div className='col-md-4'>
          <SectionForm
            id='create-ticket-status'
            className='mb-10'
            commonClassNames='row d-flex mb-5 align-items-center'
            commonLabelClass='col-md-5'
            commonInputClass='col'
            register={register}
            control={control}
            inputs={[
              {
                id: 'assignee',
                label: 'Assignee',
                isSelect: true,
                placeholder: 'Select Assignee',
                isNullable: true,
                options: projectMembers,
                isLoading: isLoadingProjectMembers,
                labelKey: 'name',
                valueKey: 'username',
                registerKey: 'assignedTo',
              },
              {
                id: 'kanban-stage',
                label: 'Kanban Stage',
                isSelect: true,
                options: projectKanbanStages,
                isLoading: isLoadingProjectKanbanStages,
                registerOptions: {valueAsNumber: true},
                valueType: 'number',
                labelKey: 'name',
                valueKey: 'column_id',
                registerKey: 'currentColumnId',
              },
              {
                id: 'module',
                label: 'Module',
                isSelect: true,
                options: projectModules,
                isLoading: isLoadingProjectModules,
                labelKey: 'name',
                valueKey: 'module_id',
                registerKey: 'moduleId',
              },
              {
                id: 'status',
                label: 'Status',
                isSelect: true,
                placeholder: 'Select Status',
                isNullable: true,
                options: projectStatuses,
                isLoading: isLoadingProjectStatuses,
                registerOptions: {valueAsNumber: true},
                placeholderValue: 0,
                labelKey: 'label',
                valueKey: 'id',
                registerKey: 'status',
              },
              {
                id: 'priority',
                label: 'Priority',
                isSelect: true,
                placeholder: 'Select Priority',
                isNullable: true,
                options: projectPriorities,
                isLoading: isLoadingProjectPriorities,
                registerOptions: {valueAsNumber: true},
                placeholderValue: 0,
                labelKey: 'label',
                valueKey: 'id',
                registerKey: 'priority',
              },
              {
                id: 'estimation-time',
                label: 'Estimation',
                isInputTime: true,
                registerKey: 'estimation',
              },
              {
                id: 'spent-time',
                label: 'Spent Time',
                isInputTime: true,
                registerKey: 'spentTime',
              },
              {
                id: 'due-date',
                label: 'Due Date',
                isInputDate: true,
                isNullable: true,
                registerKey: 'dueDate',
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default CreateTicketModal
