import {convertSelectedValueType, dataToSelectOptions} from '../../utils/common'
import {SelectOptionsType, SelectPropsType} from './SelectTypes'

function Select({
  id,
  name,
  value,
  label,
  className = '',
  selectClass = '',
  onChange,
  disabled = false,
  isRequired,
  options,
  formLabel,
  error,
  touched,
  placeholder,
  isNullable,
  defaultValue = '',
  selectRef,
  register,
  onBlur,
  placeholderValue = '',
  isReadOnly = false,
  labelClass = '',
  labelKey = 'label',
  valueKey = 'value',
  valueType = 'string',
  isLoading = false,
  ...rest
}: SelectPropsType) {
  const {
    name: registerName = name,
    onChange: registerOnChange = onChange,
    onBlur: registerOnBlur = onBlur,
    ref: registerRef = selectRef,
  } = register && register.name ? register : {}

  const formattedOptions =
    options && options?.length > 0
      ? labelKey && valueKey
        ? dataToSelectOptions(options, labelKey, valueKey, valueType)
        : dataToSelectOptions(options, 'label', 'value', valueType)
      : []

  const handleChange = (e: any) => {
    if (onChange) onChange(e, convertSelectedValueType(options, e.target.value))
    if (registerOnChange) registerOnChange(e)
  }

  const handleBlur = (e: any) => {
    if (onBlur) onBlur(e)
    if (registerOnBlur) registerOnBlur(e)
  }

  const handleRef = (e: any) => {
    if (selectRef) (selectRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = e
    if (registerRef) registerRef(e)
  }

  return (
    <div className={'position-relative ' + className}>
      {label && (
        <label
          className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}
          form-label={formLabel}
        >
          {label}
        </label>
      )}

      {formattedOptions && formattedOptions?.length > 0 ? (
        <select
          className={`form-select ${
            isLoading || options?.length === 0 ? 'bg-white' : ''
          } ${selectClass} ${error ? 'is-invalid' : ''}`}
          data-control='select2'
          data-placeholder='Select an option'
          data-hide-search='true'
          id={id}
          name={registerName || name}
          ref={handleRef}
          onChange={handleChange}
          onBlur={handleBlur}
          {...(value ? {value: value} : {})}
          disabled={disabled || isReadOnly || isLoading || options?.length === 0}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...rest}
        >
          {placeholder && (
            <option
              key={'default'}
              value={placeholderValue}
              className='text-gray-500'
              disabled={!isNullable}
            >
              {placeholder}
            </option>
          )}
          {formattedOptions.map((option: SelectOptionsType) => {
            return (
              <option
                key={option.value}
                value={option.value}
                className='text-dark'
                selected={
                  option?.value?.toString() === defaultValue?.toString()
                    ? defaultValue
                    : !placeholder
                    ? formattedOptions?.[0]?.value
                    : false
                }
              >
                {option.label}
              </option>
            )
          })}
        </select>
      ) : (
        <select
          className={`form-select text-gray-500 ${
            isLoading || formattedOptions?.length === 0 ? '' : ''
          } ${selectClass} ${error ? 'is-invalid' : ''}`}
          disabled
        >
          <option>
            {isLoading ? 'Loading...' : formattedOptions?.length === 0 && 'No options found.'}
          </option>
        </select>
      )}

      {isLoading && (
        <div className='position-absolute d-flex justify-content-end'>
          <span className='text-primary spinner-border spinner-border-sm align-middle z-index-1 me-8 bg-light-secondary'></span>
        </div>
      )}

      {error && error?.message && (
        <div className='form-error invalid-feedback'>{error.message}</div>
      )}
    </div>
  )
}

export default Select
