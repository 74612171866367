import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'
import Archived from './layouts/Archived'
import Active from './layouts/Active'
import ProjectsPageNavigation from './components/ProjectsPageNavigation'

const ProjectsPageCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/projects',
    isSeparator: false,
    isActive: false,
  },
]

function ProjectsPage() {
  useMeta('Projects')

  return (
    <Routes>
      <Route
        element={
          <>
            <ProjectsPageNavigation />
            <Outlet />
          </>
        }
      >
        <Route
          path='/active'
          element={
            <>
              <PageTitle breadcrumbs={ProjectsPageCrumbs}>Active</PageTitle>
              <Active />
            </>
          }
        />

        <Route
          path='/archived'
          element={
            <>
              <PageTitle breadcrumbs={ProjectsPageCrumbs}>Archived</PageTitle>
              <Archived />
            </>
          }
        />
        <Route index element={<Navigate to='/projects/active' />} />
      </Route>
    </Routes>
  )
}

export default ProjectsPage
