import {useContext} from 'react'
import {getInquiryBadgeColor} from '../../../../utils/badge'
import {InquiresPageListingContext} from '../../context'
import usePermission from '../../../../hook/usePermission'
import {trimMessage} from '../../utils'
import {formatDate} from '../../../../utils/date'
import Initials from '../../../../components/Initials'

function InquiresPageTableRow({row, actionComponent, columns}: any) {
  const {setOpenModal, setClickedId} = useContext(InquiresPageListingContext)
  const {hasPermission} = usePermission()
  const isPermission = hasPermission('products_product inquiries', 'write')

  const handleOpen = () => {
    if (isPermission) {
      setOpenModal(true)
      setClickedId(row?.id)
    }
  }

  return (
    <tr>
      {columns.map((column: any) => {
        const [key] = Object.entries(column)[0]
        let cellContent

        // Render content based on the key
        switch (key) {
          case 'date_submitted':
            cellContent = <div className='text-dark '>{formatDate(row?.date_submitted, false)}</div>
            break
          case 'product_name':
            cellContent = (
              <div onClick={handleOpen} className='cursor-pointer'>
                {row?.product_name || '-'}
              </div>
            )
            break
          case 'sku':
            cellContent = (
              <div onClick={handleOpen} className='cursor-pointer'>
                {row?.sku || '-'}
              </div>
            )
            break
          case 'message':
            cellContent = (
              <div onClick={handleOpen} className='cursor-pointer'>
                {trimMessage(row?.message)}
              </div>
            )
            break
          case 'customer_name':
           cellContent = (
             <div className='d-flex'>
               <div className='symbol symbol-circle symbol-35px me-3'>
                 <Initials text={row?.customer_name?.[0]} />
               </div>
               <div className='d-flex flex-column'>
                 <div className='text-dark '>{row?.customer_name || '-'} </div>
                 <div className='align-items-center text-muted'>{row?.customer_email || '-'}</div>
               </div>
             </div>
           )
            break
          case 'rep_name':
            cellContent = (
              <div className='d-flex'>
                <div className='symbol symbol-circle symbol-35px me-3'>
                  <Initials text={row?.rep_name?.[0]} />
                </div>
                <div className='d-flex flex-column'>
                  <div className='text-dark '>{row?.rep_name || '-'} </div>
                  <div className='align-items-center text-muted'>{row?.rep_email || '-'}</div>
                </div>
              </div>
            )
            break
          case 'status':
            cellContent = (
              <span className={`badge ${getInquiryBadgeColor(row?.status, 'light')} badge-lg`}>
                <div className='align-items-center'>{row?.status || '-'}</div>
              </span>
            )
            break
          case 'action':
            cellContent = actionComponent ? actionComponent(row) : null
            break
          default:
            cellContent = null
        }

        return (
          <td key={key} className='text-hover-primary'>
            {cellContent}
          </td>
        )
      })}
    </tr>
  )
}

export default InquiresPageTableRow
