import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../context'
import {AddBoardModuleModalTypes} from '../../interfaces'
import {AddBoardModuleSchema} from '../../validations'
import {AddBoardModuledefaultValues} from '../../utils'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'

const AddBoardModuleModal = ({show, onClose, isEdit, moduleData}: AddBoardModuleModalTypes) => {
  const {isLoadingBoardModulesList, createModule, updateModule, isLoadingModulesOperation} =
    useContext(BoardSettingsPageContext)
  const defaultValues = AddBoardModuledefaultValues(moduleData)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(AddBoardModuleSchema),
  })

  const onSubmit = async (data: any) => {
    if (isEdit) {
      await updateModule(`${moduleData?.id}`, data)
    } else {
      await createModule(data)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Module`}
      onAction={handleSubmit(onSubmit)}
      actionName={`${isEdit ? 'Update' : 'Save'}`}
      actionBtnClass='btn-primary'
      isDataLoading={isLoadingBoardModulesList}
      isOperationLoading={isLoadingModulesOperation}
      body={
        <>
          <label htmlFor={`module-name`} className='required form-label'>
            Module Name
          </label>
          <input
            id={'module-name'}
            type='text'
            className='form-control'
            placeholder='Type Module Name'
            {...register('module_name', {required: true})}
          />
          {errors.module_name && <span className='text-danger'>This field is required</span>}
        </>
      }
    />
  )
}

export default AddBoardModuleModal
