import {isEmpty} from '../../../utils/common'
import {capitalizeFirstLetter} from '../../../utils/string'

export const dataToColumnsListing = (data: any) => {
  if (isEmpty(data)) return []

  return data.map((column: any) => {
    return {
      columnId: column['column_id'],
      columnName: capitalizeFirstLetter(column['name']) || '-',
      cards: column['cards'] || 0,
      description: column['description'],
    }
  })
}
