import {createContext} from 'react'

export const TicketDetailsPageContext = createContext({
  hasTicketWritePermission: false,

  projectStatus: '',
  projectName: '',
  projectId: '',
  moduleId: '',
  cardId: '',

  ticketDetails: [] as any,
  isLoadingTicketDetails: false,

  projectMembers: [],
  isLoadingProjectMembers: false,

  projectKanbanStages: [],
  isLoadingProjectKanbanStages: false,

  projectModules: [],
  isLoadingProjectModules: false,

  projectStatuses: [],
  isLoadingProjectStatuses: false,

  projectPriorities: [],
  isLoadingProjectPriorities: false,

  updateTicket: (data: any) => {},
  isLoadingTicketOperation: false,

  createComment: (commentData: any) => {},
  editComment: (commentId: any, data: any) => {},
  deleteComment: (commentId: any) => {},
  isLoadingCommentOperation: false,

  customFieldsList: [],
  isLoadingCustomFieldsList: false,

  commentsList: [],
  isLoadingCommentsList: false,

  updateCustomField: (ticketCFieldData: any) => {},
  isLoadingCFieldsOperation: false,

  isTicketEditable: false,
  setIsTicketEditable: (value: boolean) => {},
})
