export const reorderColumns = (defaultCols: any[], globalCols: any[]) => {
  const reordered: any = []

  // Add matching columns from globalDefaultColumnsList in the same order
  globalCols.forEach((globalCol) => {
    const key = Object.keys(globalCol)[0] // get the key
    const matchedCol = defaultCols.find((col) => col.hasOwnProperty(key))
    if (matchedCol) {
      reordered.push(matchedCol)
    }
  })

  // Add the remaining columns from defaultColumns that were not in globalDefaultColumnsList
  defaultCols.forEach((defaultCol) => {
    const key = Object.keys(defaultCol)[0]
    const isAlreadyAdded = reordered.some((col: any) => col.hasOwnProperty(key))
    if (!isAlreadyAdded) {
      reordered.push(defaultCol)
    }
  })

  return reordered
}
