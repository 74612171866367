const ProjectMemberActionDropdown = ({setShowDeleteModal}: any) => {
  const handleRemove = () => {
    setShowDeleteModal(true)
    document.body.click()
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <a href='###' onClick={handleRemove} className='btn btn-active-text-danger'>
        Remove
      </a>
    </div>
  )
}
export default ProjectMemberActionDropdown
