import {useState} from 'react'
import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

function useCustomFieldsOperations() {
  const Api = useApi()
  const {projectId, cardId} = useParams() as {projectId: string; cardId: string}
  const [isLoading, setLoading] = useState(false)

  async function updateCustomField(payload: any) {
    return Api.post(`/agile/projects/${projectId}/${cardId}/custom-fields`, payload, {
      successMessage: 'Ticket updated successfully',
      setLoading: setLoading,
      refetch: 'additional-custom-fields',
    })
  }

  return {
    updateCustomField,
    isLoading,
  }
}

export default useCustomFieldsOperations
