import {Pagination} from '../../../utils/pagination'
import {ActivePageHeader} from '../sections/headers/ActivePageHeader'
import ActiveProjectsTable from '../sections/tables/ActiveProjectsTable'
import {ProjectsListPageContext} from '../context/index'
import {useGetBusinessUnitsList} from '../hooks/useGetBusinessUnitsList'
import {useGetProjectsList} from '../hooks/useGetProjectsList'
import useProjectOperations from '../hooks/useProjectOperations'

function Active() {
  const {
    projectsList,
    isLoading,
    pagination,
    onSearch,
    onPageChange,
    filters,
    refetchProjectsList,
    onSortingChange,
  } = useGetProjectsList()
  const {businessUnitsList, isLoadingBusinessUnits} = useGetBusinessUnitsList()
  const {
    createNewProjectDetails,
    updateProjectDetails,
    addProjectToFavorite,
    isLoading: isOperationLoading,
  } = useProjectOperations()

  const contextValue = {
    projectsList,
    isLoading,
    isOperationLoading,
    filters,
    businessUnitsList,
    isLoadingBusinessUnits,
    onSearch,
    updateProjectDetails,
    createNewProjectDetails,
    refetchProjectsList: refetchProjectsList,
    onSortingChange,
    addProjectToFavorite,
  }

  return (
    <>
      <ProjectsListPageContext.Provider value={contextValue}>
        <ActivePageHeader />
        <ActiveProjectsTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectsListPageContext.Provider>
    </>
  )
}

export default Active
