export const formatProjectDetails = (row: any) => ({
  id: row.id,
  buId: row?.business_unit_id,
  name: row?.name,
  description: row?.description,
  projectInitials: row?.project_initials,
  businessUnit: row?.business_unit,
  members: row?.members,
  ownerName: row?.owner_name,
  createdAt: row?.created_at,
  updatedAt: row?.updated_at,
  isFevorite: row?.is_fevorite,
  isArchived: row?.is_archived,
})

export const defaultProjectCreateData = {
  name: '',
  description: '',
  buId: 0,
}
