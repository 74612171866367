import {Route, Routes, Outlet, Navigate, useParams} from 'react-router-dom'
import ProjectPageNavigation from './components/ProjectPageNavigation'
import AgileBoard from './layouts/AgileBoard'
import useMeta from '../../hook/useMeta'
import BoardSetting from './layouts/BoardSetting'
import Members from './layouts/Members'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {ProjectsMainPageContext} from './context'
import usePermission from '../../hook/usePermission'

function ProjectPage() {
  useMeta('Project')

  const {hasPermission} = usePermission()
  const params: any = useParams<any>()
  const projectId: any = params?.projectId
  const projectName: any = params?.projectName
  const projectStatus: any = params?.projectStatus

  const contextValue = {
    projectStatus,
    projectName,
    projectId: projectId,
  }

  const analyticsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Projects',
      path: `/projects/`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `${projectName}`,
      path: `/projects/${projectStatus}/${projectName}/${projectId}`,
      isSeparator: false,
      isActive: true,
    },
  ]

  return (
    <ProjectsMainPageContext.Provider value={contextValue}>
      <Routes>
        <Route
          element={
            <>
              <ProjectPageNavigation />
              <Outlet />
            </>
          }
        >
          <Route
            path='/agile-board'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>Agile Board</PageTitle>
                <AgileBoard />
              </>
            }
          />

          <Route
            path='/members'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>Members</PageTitle>
                <Members />
              </>
            }
          />

          {hasPermission('projects_all projects', 'write') && (
            <Route
              path='/board-setting'
              element={
                <>
                  <PageTitle breadcrumbs={analyticsBreadCrumbs}>Board Settings</PageTitle>
                  <BoardSetting />
                </>
              }
            />
          )}

          <Route
            index
            element={
              <Navigate to={`/projects/${projectStatus}/${projectName}/${projectId}/agile-board`} />
            }
          />
        </Route>
      </Routes>
    </ProjectsMainPageContext.Provider>
  )
}

export default ProjectPage
