import {useState} from 'react'
import useToastify from '../../../../hook/useToastify'
import { useGetGlobalProjectsMembersList } from './useGetGlobalProjectsMembersList'
import { addGProjectMember, removeGProjectMember } from '../../services'


export default function useGlobalProjectsMembersOperations() {
  const {toastMessage} = useToastify()
  const [isLoading, setIsLoading] = useState(false)
  const {refetchGlobalProjectsMembersList} = useGetGlobalProjectsMembersList()

  async function addGlobalProjectMember(globalProjectMemberDetails: any) {
    setIsLoading(true)
    const res: any = await addGProjectMember(globalProjectMemberDetails)
    if (res.status === 200) {
      toastMessage('success', `Member Added successfully`)
      refetchGlobalProjectsMembersList()
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function removeGlobalProjectMember(globalMemberUsername: string) {
    try {
      setIsLoading(true)
      const response = await removeGProjectMember(globalMemberUsername)
      if (response.status === 200) {
        toastMessage('success', `Member removed successfully`)
        refetchGlobalProjectsMembersList()
      } else {
        toastMessage('error', `${response.data.message}`)
      }
      setIsLoading(false)

      return response
    } catch (error: any) {
      setIsLoading(false)
    }
  }

  return {
    addGlobalProjectMember,
    removeGlobalProjectMember,
    isLoadingGlobalProjectsMembersOperation: isLoading,
  }
}
