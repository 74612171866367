import {Controller} from 'react-hook-form'
import EditorComponent from './EditorComponent'

interface TextEditorTypes {
  id: string
  className?: string
  onChange?: (value: any) => void
  control?: any
  inputClass?: string
  registerKey?: string
  label?: string
  isRequired?: boolean
  error?: any
  errorClass?: string
  errorMessageRef?: any
  readOnly?: boolean
  disabled?: any
  maxLength?: number
  rules?: any
  textEditorRef?: any
  value?: any
  defaultValue?: string | null
  hasDefaultValue?: boolean
  isChangeOnSave?: boolean
  isLoadingOperation?: boolean
  isLoadingData?: boolean
  isReadOnly?: boolean
  editorClass?: string
  isClearChanges?: boolean
}

const TextEditor = ({
  className = '',
  onChange,
  control,
  inputClass = '',
  registerKey,
  label,
  isRequired,
  error,
  errorClass,
  errorMessageRef,
  readOnly,
  disabled,
  value,
  defaultValue = null,
  textEditorRef,
  maxLength,
  id,
  rules,
  isChangeOnSave = false,
  isLoadingOperation = undefined,
  isLoadingData = undefined,
  isReadOnly = false,
  editorClass = '',
  value: any,
  isClearChanges = false
}: TextEditorTypes) => {
  return (
    <div className={className} id={id}>
      {label && <label className={`form-label ${isRequired ? 'required' : ''}`}>{label}</label>}
      {control && registerKey ? (
        <Controller
          name={registerKey}
          control={control}
          rules={rules}
          render={({field: {onChange: registerOnChange, value: registeredValue}}) => (
            <>
              <EditorComponent
                id={id}
                onChange={(value: any) => {
                  registerOnChange(value)
                  onChange?.(value)
                }}
                inputClass={error?.message ? 'border border-danger' + inputClass : inputClass}
                readOnly={readOnly}
                disabled={disabled}
                value={value ?? registeredValue}
                defaultValue={defaultValue}
                textEditorRef={textEditorRef}
                maxLength={maxLength}
                isChangeOnSave={isChangeOnSave}
                isLoadingOperation={isLoadingOperation}
                isLoadingData={isLoadingData}
                isReadOnly={isReadOnly}
                editorClass={editorClass}
                isClearChanges={isClearChanges}
              />
              {error?.message && (
                <>
                  <div className='is-invalid d-none'></div>
                  <p className={errorClass ? errorClass : 'invalid-feedback'} ref={errorMessageRef}>
                    {error?.message}
                  </p>
                </>
              )}
            </>
          )}
        />
      ) : (
        <EditorComponent
          id={id}
          onChange={onChange}
          inputClass={inputClass}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          textEditorRef={textEditorRef}
          maxLength={maxLength}
          isChangeOnSave={isChangeOnSave}
          isLoadingOperation={isLoadingOperation}
          isLoadingData={isLoadingData}
          isReadOnly={isReadOnly}
          editorClass={editorClass}
          isClearChanges={isClearChanges}
        />
      )}
    </div>
  )
}

export default TextEditor
