export function mergedAndFormattedApiData(agileBoardStructure: any, agileBoardData: any) {
  const mergedData: any = []

  agileBoardStructure?.forEach((structureItem: any) => {
    const matchingData = agileBoardData?.find(
      (dataItem: any) =>
        dataItem?.moduleName === structureItem?.moduleName &&
        dataItem?.columnName === structureItem?.columnName
    )

    if (matchingData) {
      mergedData?.push({
        ...structureItem,
        columnItems: matchingData?.columnItems,
      })
    } else {
      mergedData?.push({
        ...structureItem,
        columnItems: [],
      })
    }
  })

  const uniqueModuleNames = mergedData
    ?.map((item: any) => ({
      moduleName: item?.moduleName,
      moduleID: item?.moduleID,
    }))
    ?.filter(
      (value: any, index: any, self: any) =>
        self?.findIndex((obj: any) => obj?.moduleName === value?.moduleName) === index
    )

  const uniqueColumnNames = mergedData
    ?.map((item: any) => ({
      columnName: item?.columnName,
      cID: item?.cId,
    }))
    ?.filter(
      (value: any, index: any, self: any) =>
        self?.findIndex((obj: any) => obj?.columnName === value?.columnName) === index
    )

  const updatedTasksList = mergedData?.flatMap((column: any) =>
    column?.columnItems?.flatMap((task: any) => ({
      ...task,
    }))
  )

  return {mergedData, uniqueModuleNames, uniqueColumnNames, updatedTasksList}
}
