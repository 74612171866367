import {useState} from 'react'
import {CommentsTab} from '../sections/tabs/CommentsTab'

function CommentSectionNavigation({className = ''}: any) {
  const tabs = [{label: 'Comments', id: 'comment-tab', content: <CommentsTab />}]

  const [activeTab, setActiveTab] = useState(tabs[0].id)

  const handleTabChange = (tabId: any) => {
    setActiveTab(tabId)
  }

  return (
    <div className={className}>
      <ul className='nav nav-tabs nav-line-tabs lh-2 fs-6'>
        {tabs.map((tab: any) => (
          <li className='nav-item' key={tab.id}>
            <a
              href='###'
              className={`nav-link ${
                activeTab === tab.id
                  ? 'active border-bottom border-2 border-primary fw-bold text-primary'
                  : 'fw-bold'
              }`}
              onClick={() => handleTabChange(tab.id)}
            >
              {tab.label}
            </a>
          </li>
        ))}
      </ul>

      <div className='tab-content'>
        {tabs.map((tab: any) => (
          <div
            className={`tab-pane fade ${activeTab === tab.id ? 'active show' : ''}`}
            key={tab.id}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CommentSectionNavigation
