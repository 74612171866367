import {DatePicker} from 'rsuite'
import InputDateTypes from './inputDateTypes'

const InputDate = ({
  id,
  className = '',
  inputClass = '',
  labelClass = '',
  isRequired = false,
  label,
  onChange,
  format = 'yyyy-MM-dd',
  menuClassName = 'input-date-picker',
  placement = 'auto',
  error,
  errorClass,
  cleanable = false,
  value,
  defaultValue,
  placeholder,
  isLoading,
  isDisabled,
  isReadOnly,
}: InputDateTypes) => {
  return (
    <div className={'position-relative ' + className} id={id}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <DatePicker<any>
        id={id}
        className={`d-flex w-100 z-index-0 position-relative px-0 ${inputClass}`}
        placement={placement}
        menuClassName={menuClassName}
        format={format}
        cleanable={cleanable}
        onChange={onChange}
        {...(value !== undefined && value !== null ? {value: new Date(value)} : {})}
        defaultValue={
          defaultValue !== undefined && defaultValue !== null ? new Date(defaultValue) : null
        }
        placeholder={placeholder}
        disabled={isDisabled}
        readOnly={isLoading || isReadOnly}
      />
      {isLoading && (
        <div className='position-absolute d-flex justify-content-end'>
          <span className='text-primary spinner-border spinner-border-sm align-middle z-index-1 me-8 bg-white'></span>
        </div>
      )}
      {error && error?.message ? (
        <p className={`invalid-feedback ${errorClass}`}>{error.message}</p>
      ) : null}
    </div>
  )
}

export default InputDate
