import {useEffect, useRef, useState} from 'react'
import {convertInputToReadableFormat} from './utils/convertInputToReadableFormat'
import {convertInputToHoursAndMinutes} from './utils/convertInputToHoursAndMinutes'
import {validateInput} from './utils/validateInput'
import {InputTimeTypes} from './InputTimeTypes'

const InputTime = ({
  id,
  popupPositionClass = 'end-0',
  days = 5,
  hours = 8,
  isHourConversion = false,
  defaultValue = '',
  clearBtn,
  handleSelectedTime,
  isPopupDefaultValue = false,
  isTempAddition = false,
  isLoading,
  label,
  isRequired = false,
  labelClass = '',
  className = '',
  inputClass = '',
  error,
  errorClass = '',
  isReadOnly,
  isDisabled,
}: InputTimeTypes) => {
  const defaultTempSelectedTime =
    isPopupDefaultValue === undefined && defaultValue
      ? !isHourConversion
        ? convertInputToReadableFormat(defaultValue, days, hours)
        : convertInputToHoursAndMinutes(defaultValue, days, hours)
      : ''
  const defaultConvertedTime =
    defaultValue && !isHourConversion
      ? !isTempAddition
        ? convertInputToReadableFormat(defaultValue, days, hours)
        : convertInputToHoursAndMinutes(defaultValue, days, hours)
      : ''

  const [tempSelectedTime, setTempSelectedTime] = useState(defaultTempSelectedTime)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [inputError, setInputError] = useState('')
  const [convertedTime, setConvertedTime] = useState(defaultConvertedTime)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [tempAddition, setTempAddition] = useState('')
  const [tempConvertedTime, setTempConvertedTime] = useState(convertedTime)
  const [isDefaultValueAssigned, setIsDefaultValueAssigned] = useState(false)

  const popupRef = useRef(null)

  useEffect(() => {
    if (defaultValue !== '' && !isDefaultValueAssigned) {
      setTempAddition(
        isHourConversion
          ? convertInputToHoursAndMinutes(defaultValue, days, hours)
          : convertInputToReadableFormat(defaultValue, days, hours)
      )
      setTempConvertedTime(
        isHourConversion
          ? convertInputToHoursAndMinutes(defaultValue, days, hours)
          : convertInputToReadableFormat(defaultValue, days, hours)
      )
      setConvertedTime(defaultConvertedTime)
      setTempSelectedTime(defaultTempSelectedTime)
      setIsDefaultValueAssigned(true)
    }
  }, [
    defaultValue,
    isHourConversion,
    days,
    hours,
    defaultConvertedTime,
    defaultTempSelectedTime,
    isDefaultValueAssigned,
  ])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !(popupRef.current as any).contains(event.target)) {
        setPopupOpen(false)
        setInputError('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupRef])

  useEffect(() => {
    setIsButtonDisabled(inputError !== '' || tempSelectedTime.trim() === '')
  }, [inputError, tempSelectedTime])

  const handleCancel = () => {
    setPopupOpen(false)
    setInputError('')
    setTempSelectedTime(isPopupDefaultValue ? convertedTime : '')
  }

  const handleApplyChanges = () => {
    if (isHourConversion) {
      const convertedValue = convertInputToHoursAndMinutes(tempSelectedTime, days, hours)
      if (!inputError && convertedValue !== '') {
        if (isTempAddition) {
          const finalValue: any = `${tempSelectedTime}` + tempConvertedTime
          setTempAddition(convertInputToHoursAndMinutes(finalValue, days, hours))
          setTempConvertedTime(convertInputToHoursAndMinutes(finalValue, days, hours))
        }

        setConvertedTime(convertedValue)
        setPopupOpen(false)

        if (isPopupDefaultValue) {
          setTempSelectedTime(convertedValue)
        } else {
          setTempSelectedTime('')
        }

        handleSelectedTime?.(convertedValue)
      }
    } else {
      const convertedValue = convertInputToReadableFormat(tempSelectedTime, days, hours)
      if (!inputError && convertedValue !== '') {
        if (isTempAddition) {
          const finalValue: any = `${tempSelectedTime} ` + tempConvertedTime
          setTempAddition(convertInputToReadableFormat(finalValue, days, hours))
          setTempConvertedTime(convertInputToReadableFormat(finalValue, days, hours))
        }

        setConvertedTime(convertedValue)
        setPopupOpen(false)

        if (isPopupDefaultValue) {
          setTempSelectedTime(convertedValue)
        } else {
          setTempSelectedTime('')
        }

        handleSelectedTime?.(convertedValue)
      }
    }
  }

  const popupInputRef: any = useRef(null)
  useEffect(() => {
    if (isPopupOpen && popupInputRef.current) {
      popupInputRef.current.focus()
    }
  }, [isPopupOpen])

  const handleClearInput = () => {
    setTempSelectedTime('')
    setConvertedTime('')
    setTempAddition('')
    setTempConvertedTime('')
  }

  return (
    <div
      className={'position-relative d-flex align-items-center justify-content-end ' + className}
      id={id}
    >
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <input
        id={id}
        type='text'
        value={isTempAddition ? tempAddition : convertedTime}
        onChange={(e) => setTempSelectedTime(e.target.value)}
        className={`form-control pe-xxl-5 form-control-solid ${inputClass}`}
        placeholder='?'
        onClick={() => !isReadOnly && !isDisabled && setPopupOpen(true)}
        readOnly
        disabled={isDisabled}
      />
      {error && error?.message ? (
        <p className={`invalid-feedback ${errorClass}`}>{error.message}</p>
      ) : null}
      {isLoading && (
        <div className='position-absolute d-flex justify-content-end'>
          <span className='text-primary spinner-border spinner-border-sm align-middle z-index-1 me-2 bg-light-secondary'></span>
        </div>
      )}
      {isPopupOpen && (
        <div
          ref={popupRef}
          className={`mt-2 p-5 d-flex flex-column min-w-100px gap-4 bs-popover-auto top-100 popover  position-absolute bg-white border border-2 z-index-2 rounded-2 ${popupPositionClass}`}
        >
          <div className='m-dropdown__content d-flex flex-column gap-1'>
            <input
              type='text'
              className='form-control m-input'
              data-bs-toggle='tooltip'
              ref={popupInputRef}
              data-bs-placement='bottom'
              placeholder='1w 1d 1h 1m'
              value={tempSelectedTime}
              onKeyDown={(e) => (e.key === 'Enter' ? handleApplyChanges() : null)}
              onChange={(e) => {
                setTempSelectedTime(e.target.value)
                validateInput(e.target.value, setInputError)
              }}
            />
            <span className='text-danger p-1'>{inputError}</span>
          </div>
          <div className='d-flex justify-content-between m-dropdown__footer gap-3'>
            {clearBtn && (
              <button className='btn btn-light w-35px' onClick={handleClearInput}>
                <i className='fa-solid fa-trash'></i>
              </button>
            )}
            <div className='d-flex gap-3'>
              <button className='btn btn-light' onClick={handleCancel}>
                Cancel
              </button>
              <button
                disabled={isButtonDisabled}
                className={`btn btn-primary ${(!tempSelectedTime || inputError) && 'btn-disabled'}`}
                onClick={handleApplyChanges}
              >
                Apply Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default InputTime
