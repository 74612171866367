import {RadioGroupTypes} from './RadioGroupTypes'

function RadioGroup({
  id,
  name,
  value,
  className = '',
  groupClass = '',
  inputClass = '',
  label,
  register,
  isRequired,
  onChange,
  onBlur,
  radioButtonRef,
  onKeyDown,
  checkedValue,
  disabled = false,
  error,
  isReadOnly = false,
  labelClass = '',
  ...rest
}: RadioGroupTypes) {
  const {
    name: registerName = name,
    onChange: registerOnChange = onChange,
    onBlur: registerOnBlur = onBlur,
    ref: registerRef = radioButtonRef,
  } = register && register.name ? register : {};

  const handleChange = (e: any) => {
    if (onChange) onChange(e)
    if (registerOnChange) registerOnChange(e)
  }

  const handleBlur = (e: any) => {
    if (onBlur) onBlur(e)
    if (registerOnBlur) registerOnBlur(e)
  }

  const handleRef = (e: any) => {
    if (radioButtonRef)
      (radioButtonRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = e
    if (registerRef) registerRef(e)
  }

  return (
    <div className={className}>
      <div className={`form-check form-check-sm form-check-custom ${groupClass}`}>
        <input
          id={id}
          name={registerName || name}
          ref={handleRef}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`form-check-input ${inputClass} ${error ? 'is-invalid' : ''}`}
          type='radio'
          value={value}
          onKeyDown={onKeyDown}
          disabled={disabled}
          readOnly={isReadOnly}
          {...rest}
        />
        {label && (
          <span className={`form-check-label ${labelClass} ${isRequired ? 'required' : ''}`}>{label}</span>
        )}
      </div>
    </div>
  )
}

export default RadioGroup
