const BoardColumnsActionDropdown = ({setShowModal, setDeleteModal}: any) => {
  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setDeleteModal(true)
    document.body.click()
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <span className='btn btn-active-text-primary' onClick={handleEditClick}>
        Edit
      </span>
      <span onClick={handleDeleteClick} className='btn btn-active-text-danger'>
        Delete
      </span>
    </div>
  )
}

export default BoardColumnsActionDropdown
