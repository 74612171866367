import {useState} from 'react'
import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'

export default function useAgileBoardOperations() {
  const Api = useApi()
  const {projectId}: any = useParams<any>()
  const [isLoading, setLoading] = useState(false)

  async function createTicket(moduleId: string, data: any) {
    return Api.post(`/agile/projects/${projectId}/modules/${moduleId}/cards`, data, {
      setLoading: setLoading,
      successMessage: 'Ticket created successfully',
      refetch: ['agile-board-structure', 'agile-board-data'],
    })
  }

  return {
    createTicket,
    isLoading,
  }
}
