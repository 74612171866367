import {useAppSelector} from '../../../../redux/useTypedSelector'
import {useState} from 'react'
import useApi from '../../../../services/useApi'
import {useParams} from 'react-router-dom'

function useModulesOperations() {
  const Api = useApi()
  const {user} = useAppSelector((state) => state.auth)
  const {projectId} = useParams()
  const [isLoading, setLoading] = useState(false)

  async function createModule(payload: any) {
    return Api.post(`/agile/projects/${projectId}/modules`, payload, {
      successMessage: 'Module created successfully.',
      setLoading: setLoading,
      refetch: 'board-modules',
    })
  }

  async function updateModule(moduleId: string, payload: any) {
    return Api.patch(
      `/agile/projects/${projectId}/modules/${moduleId}`,
      {
        name: payload['module_name'],
        updated_by: user['username'],
      },
      {
        successMessage: 'Updated successfully.',
        setLoading: setLoading,
        refetch: 'board-modules',
      }
    )
  }

  async function updateModuleTableSorting(sortOrder: any) {
    return Api.patch(`/agile/projects/${projectId}/modules`, sortOrder, {
      successMessage: 'Module updated successfully.',
      setLoading: setLoading,
    })
  }

  async function deleteModule(moduleId: string) {
    return Api.delete(
      `/agile/projects/${projectId}/modules/${moduleId}`,
      {},
      {
        successMessage: 'Module deleted successfully.',
        setLoading: setLoading,
        refetch: 'board-modules',
      }
    )
  }

  return {
    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    isLoading,
  }
}

export default useModulesOperations
