import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../context'
import {AddBoardColumnSchema} from '../../validations'
import {AddBoardColumnModalTypes} from '../../interfaces'
import {AddBoardColumndefaultValues} from '../../utils'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'

const AddBoardColumnModal = ({show, onClose, isEdit, columnData}: AddBoardColumnModalTypes) => {
  const {createColumn, updateColumn, isLoadingColumnOperation, isLoadingBoardColumnsList} =
    useContext(BoardSettingsPageContext)
  const defaultValues = AddBoardColumndefaultValues(columnData)

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(AddBoardColumnSchema),
  })

  const onSubmit = async (data: any) => {
    if (isEdit) {
      await updateColumn(`${columnData?.id}`, data)
    } else {
      await createColumn(data)
    }
    reset(defaultValues)
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Column`}
      onAction={handleSubmit(onSubmit)}
      actionName={`${isEdit ? 'Update' : 'Save'}`}
      actionBtnClass='btn-primary'
      isOperationLoading={isLoadingColumnOperation}
      isDataLoading={isLoadingBoardColumnsList}
      body={
        <>
          <div className='mb-10'>
            <label htmlFor={`column-name`} className='required form-label'>
              Column Name
            </label>
            <input
              id={'column-name'}
              type='text'
              className='form-control'
              placeholder='Type Column Name'
              {...register('column_name', {required: true})}
            />
            {errors.column_name && <span className='text-danger'>This field is required</span>}
          </div>

          <div className='mb-10'>
            <label htmlFor={`column-name`} className='required form-label'>
              Column Description
            </label>
            <input
              id={'module-description'}
              type='text'
              className='form-control'
              placeholder='Type Description'
              {...register('description', {required: true})}
            />
            {errors.column_name && <span className='text-danger'>This field is required</span>}
          </div>
        </>
      }
    />
  )
}

export default AddBoardColumnModal
