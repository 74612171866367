import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectMembersPageContext} from '../../context'
import ProjectMemberActionDropdown from '../actionDropdowns/ProjectMemberActionDropdown'

const MemberActionsOverlay = ({memberDetails}: any) => {
  const {removeProjectMember, isLoadingOperation, isLoadingProjectMembersList} =
    useContext(ProjectMembersPageContext)
  const [showDeleteModal, setDeleteModal] = useState(false)

  const onRemove = async (memberId: any) => {
    await removeProjectMember(memberId)
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={<ProjectMemberActionDropdown setDeleteModal={setDeleteModal} />}
      />

      {showDeleteModal && (
        <>
          <ConfirmationModal
            uniqueID={memberDetails?.member_id}
            show={showDeleteModal}
            onClose={() => setDeleteModal(false)}
            isOperationLoading={isLoadingOperation}
            isDataLoading={isLoadingProjectMembersList}
            onAction={onRemove}
            body={
              <p>
                Do you really want to remove this member? <br></br>This action is irreversible.
              </p>
            }
          />
        </>
      )}
    </>
  )
}

export default MemberActionsOverlay
