import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'
import {useState} from 'react'

export default function useTicketOperations() {
  const Api = useApi()
  const {projectId, cardId}: any = useParams<any>()
  const [isLoading, setLoading] = useState(false)

  async function updateTicket(data: any) {
    return await Api.patch(`/agile/projects/${projectId}/cards/${cardId}`, data, {
      setLoading: setLoading,
      successMessage: 'Ticket updated successfully',
      refetch: 'project-ticket-details',
    })
  }
  return {
    updateTicket,
    isLoading,
  }
}
