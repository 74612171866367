import {useContext} from 'react'
import {ProjectsListPageContext} from '../../context'
import ActiveProjectsTableRow from './ActiveProjectsTableRow'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import OverlayModal from '../modals/OverlayModal'
import {formatProjectDetails} from '../../parsings'

export default function ActiveProjectsTable() {
  const {projectsList, isLoading, isCreating, isUpdating, filters, onSortingChange}: any =
    useContext(ProjectsListPageContext)

  return (
    <div className='card-body p-0'>
      <DynamicTable
        id='active-projects-table'
        data={projectsList}
        sortableColumns={[
          {key: 'name', label: 'NAME', isSorted: true},
          {key: 'business_unit', label: 'BUSINESS UNIT', isSorted: true},
          {key: 'members', label: 'Members', isSorted: false},
          {key: 'owner_username', label: 'OWNER', isSorted: true},
          {key: 'created_at', label: 'CREATED DATE', isSorted: true},
          {key: 'updated_at', label: 'MODIFIED AT', isSorted: true},
          {
            key: 'action',
            label: 'ACTION',
            render: (row: any) => <OverlayModal projectDetails={row} />,
          },
        ]}
        TableRow={ActiveProjectsTableRow}
        onSortingChange={onSortingChange}
        filters={filters}
        loading={isCreating || isUpdating || isLoading}
        noDataMessage='No active projects found.'
        permissionPath='projects_all projects'
        rowParsingFunction={formatProjectDetails}
      />
    </div>
  )
}
