import {useContext, useState} from 'react'
import AddBoardColumnModal from '../../modals/AddBoardColumnModal'
import DndTable from '../../../../../components/dndTable/DndTable'
import {BoardSettingsPageContext} from '../../../context'
import ColumnActionsOverlay from '../../overlays/ColumnActionsOverlay'

export default function ColumnsTable() {
  const [showModal, setShowModal] = useState(false)
  const {
    updateColumnTableSorting,
    boardColumnsList,
    isLoadingBoardColumnsList,
    hasBoardSettingsPermission,
  } = useContext(BoardSettingsPageContext)

  const handleUpdatedTableRows = (tableData: any) => {
    const sortOrder = {
      column_ids: tableData.map((item: any) => item.id),
    }
    updateColumnTableSorting(sortOrder)
  }

  const tableData = boardColumnsList?.map((column: any) => {
    const rows = [
      {
        columnName: 'NAME',
        headerClass: 'w-100 min-w-120px',
        columnData: column.columnName,
      },
      {
        columnName: 'CARDS',
        headerClass: 'min-w-100px',
        columnData: column.cards,
      },
    ]

    if (hasBoardSettingsPermission) {
      rows.push({
        columnName: 'ACTION',
        headerClass: 'w-80px text-center',
        columnData: <ColumnActionsOverlay columnId={column.columnId} columnData={column} />,
      })
    }

    return {
      id: column.columnId,
      tableData: rows,
    }
  })

  console.log('tableData: ', tableData);
  return (
    <div className='position-relative'>
      <DndTable
        data={tableData}
        isLoading={isLoadingBoardColumnsList}
        handleUpdatedTableRows={handleUpdatedTableRows}
        isDraggable={hasBoardSettingsPermission}
      />
      {hasBoardSettingsPermission && (
        <div className='d-flex justify-content-end'>
          {!isLoadingBoardColumnsList && (
            <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
              <i className='fa-solid fa-plus'></i>Add Column
            </button>
          )}
          {showModal && (
            <AddBoardColumnModal
              show={showModal}
              isEdit={false}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      )}
    </div>
  )
}
