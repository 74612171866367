import {useContext, useRef, useState} from 'react'
import {TicketLayout} from './TicketLayout'
import {AgileBoardPageContext} from '../context'
import DndBoard from '../../../components/agileBoard/DndBoard'
import CreateTicketModal from '../sections/modals/CreateTicketModal'
import {ConfirmationModal} from '../../../components/ConfirmationModal'

export default function TicketsBoard() {
  const handleSubmitRef = useRef<any>(null)
  const [showModal, setShowModal] = useState(false)
  const [columnId, setColumnId] = useState(null)
  const [moduleId, setModuleId] = useState(null)
  const {
    agileBoardStructure,
    agileBoardData,
    isLoadingBoardStructure,
    isLoadingAgileBoardData,
    updateAgileBoard,
    hasBoardPermission,
    isLoadingProjectKanbanStages,
    isLoadingProjectModules,
    isLoadingTicketOperation,
  } = useContext(AgileBoardPageContext)

  const handleAddCardFromColumn = ({columnData}: any) => {
    setColumnId(columnData.cID)
    setModuleId(columnData.moduleID)
    setShowModal(true)
  }

  const dndBoardData = {
    agileBoardStructure,
    agileBoardData,
    isLoadingBoardStructure,
    isLoadingAgileBoardData,
    updateAgileBoard,
  }

  const onCreateTicket = () => {
    if (handleSubmitRef.current) {
      handleSubmitRef.current.submit()
    }
  }

  return (
    <>
      <DndBoard
        dndBoardData={dndBoardData}
        handleTicketLayout={TicketLayout}
        isCreateCardFromColumn={true}
        handleAddCardFromColumn={handleAddCardFromColumn}
        hasPermission={hasBoardPermission}
        isDraggable={hasBoardPermission}
      />
      {showModal && (
        <ConfirmationModal
          show={showModal}
          title='Create Ticket'
          onClose={() => setShowModal(false)}
          body={
            <CreateTicketModal
              isCreateFromBoard={true}
              columnId={columnId}
              moduleId={moduleId}
              handleSubmitRef={handleSubmitRef}
            />
          }
          dialogClassName='modal-xl'
          onAction={onCreateTicket}
          actionName='Create'
          actionBtnClass='btn-primary'
          isDisabled={isLoadingProjectKanbanStages || isLoadingProjectModules}
          isOperationLoading={isLoadingTicketOperation}
          isDataLoading={isLoadingAgileBoardData}
        />
      )}
    </>
  )
}
