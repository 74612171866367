/* eslint-disable react-hooks/exhaustive-deps */
import {useContext} from 'react'
import {InquiresPageListingContext} from '../context'
import Search from '../../../components/Search'
import {KTSVG} from '../../../_metronic/helpers'
import SideMenu from '../../../components/setting-menu/SideMenu'
import {defaultColumns} from '../utils'

function InquiresPageListingHeader() {
  const {onSearch, Representatives, onChangeCustomerFilter, refetch} = useContext(
    InquiresPageListingContext
  )
  const icon_base_url = '/media/ad-theme-icons/'

  const handleChange = (e: any) => {
    onChangeCustomerFilter(e.target.value)
  }

  return (
    <div className='d-flex mb-10 justify-content-between'>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch(searchTerm)
        }}
      />
      <div className='d-flex align-items-center'>
        <div className='me-5'>
          <select
            className='form-select'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            defaultValue={'0'}
            onChange={handleChange}
          >
            <option value=''>Representative: All</option>
            {Representatives &&
              Representatives?.length &&
              Representatives?.map((item: any, index: number) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
          </select>
        </div>
        <span
          id='kt_drawer_column_setting_toggle'
          className='cursor-pointer'
          data-kt-toggle-target='#kt_drawer_column_setting'
        >
          <KTSVG path={`${icon_base_url}settings.svg`} className='svg-icon-2' svgClassName='' />
        </span>
        <SideMenu
          defaultColumns={defaultColumns}
          permissionPath='products_product inquiries'
          refetch={refetch}
          type={'product_inquiries'}
        />
      </div>
    </div>
  )
}

export default InquiresPageListingHeader
