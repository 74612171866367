import {useState} from 'react'
import useApi from '../../../../services/useApi'
import {useParams} from 'react-router-dom'

export default function useUpdateAgileBoard() {
  const Api = useApi()
  const {projectId}: any = useParams<any>()
  const [isLoading, setLoading] = useState(false)

  async function updateAgileBoard(payload: any) {
    const taskId = payload.activeTaskId
    payload = {
      module_id: payload.newModuleId,
      current_column_id: payload.newColumnId,
    }

    return Api.patch(`/agile/projects/${projectId}/cards/${taskId}`, payload, {
      setLoading: setLoading,
      successMessage: 'Updated successfully',
    })
  }

  return {
    updateAgileBoard,
    isLoading: isLoading,
  }
}
