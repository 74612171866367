import { arrayMove } from '@dnd-kit/sortable';

export function handleDragOver(
  event: any,
  setTasks: any
) {
  const { active, over } = event;
  if (!over || active.id === over.id) return;

  const activeId = active.id;
  const overId = over.id;
  const activeType = active.data.current?.type;
  const overType = over.data.current?.type;

  if (activeType !== 'Task') return;

  setTasks((tasks: any) => {
    const activeIndex = tasks.findIndex((t: any) => t.ticketID === activeId);
    if (activeIndex === -1) return tasks;

    if (overType === 'Task') {
      const overIndex = tasks.findIndex((t: any) => t.ticketID === overId);
      if (overIndex === -1) return tasks;

      const sameColumn = tasks[activeIndex].columnID === tasks[overIndex].columnID;
      if (!sameColumn) {
        tasks[activeIndex].columnID = tasks[overIndex].columnID;
        return arrayMove(tasks, activeIndex, overIndex - 1);
      }
      return arrayMove(tasks, activeIndex, overIndex);
    }

    if (overType === 'Column') {
      tasks[activeIndex].columnID = overId;
      return [...tasks];
    }

    return tasks;
  });
}
