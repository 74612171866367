import {Pagination} from '../../../utils/pagination'
import {useGetMembersList} from '../hooks/members/useGetMembersList'
import {ProjectMembersPageContext} from '../context/index'
import {MemberPageHeader} from '../sections/headers/MemberPageHeader'
import {useGetDropdownMembers} from '../hooks/members/useGetDropdownMembers'
import MemberPageTable from '../sections/tables/memberTables/MemberPageTable'
import usePermission from '../../../hook/usePermission'
import useProjectMembersOperations from '../hooks/members/useProjectMembersOperations'

function Members() {
  const {
    projectMembersList,
    isLoading: isLoadingProjectMembersList,
    pagination,
    onSearch,
    onPageChange,
    filters,
  }: any = useGetMembersList()
  const {hasPermission} = usePermission()
  const hasMembersPermission = hasPermission('projects_all projects', 'write')
  const {
    addProjectMembers,
    removeProjectMember,
    isLoading: isLoadingOperation,
  } = useProjectMembersOperations()
  const {membersList, isLoading: isLoadingMembersList} = useGetDropdownMembers()

  const contextValue = {
    projectMembersList,
    isLoadingProjectMembersList,
    filters,
    onSearch,

    membersList,
    isLoadingMembersList,

    addProjectMembers,
    removeProjectMember,
    isLoadingOperation,

    hasMembersPermission,
  }

  return (
    <>
      <ProjectMembersPageContext.Provider value={contextValue}>
        <MemberPageHeader />
        <MemberPageTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectMembersPageContext.Provider>
    </>
  )
}

export default Members
