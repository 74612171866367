import {formatDate} from '../../../../../utils/date'
import ProjectMemberActions from '../../overlays/ProjectMemberActions'

const ProjectsMemberPageTableRow = ({row, actionComponent}: any) => {
  return (
    <tr>
      <td>{row['name']}</td>
      <td>{row['usage_count']}</td>
      <td>{formatDate(row['created_at'], false)}</td>
      <td className='text-center'>{'-'}</td>
      <td>
        <ProjectMemberActions memberDetails={row} />
      </td>
    </tr>
  )
}

export default ProjectsMemberPageTableRow
