/* eslint-disable */
import {useContext, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {TicketDetailsPageContext} from '../context'
import {SectionForm} from '../../../components/SectionForm'
import TextEditor from '../../../components/newTextEditor/TextEditor'
import CommentSectionNavigation from '../components/CommentSectionNavigation'
import {
  dataToFormFields,
  defaultProjectTicketValues,
  getUpdatedData,
  setDefaultTicketDetails,
} from '../utils'
import {isEmpty} from '../../../utils/common'
import TicketDetailsPageHeader from './headers/TicketDetailsPageHeader'

const TicketForm = () => {
  const {projectMembers, isLoadingProjectMembers} = useContext(TicketDetailsPageContext)
  const {projectKanbanStages, isLoadingProjectKanbanStages} = useContext(TicketDetailsPageContext)
  const {projectModules, isLoadingProjectModules} = useContext(TicketDetailsPageContext)
  const {projectStatuses, isLoadingProjectStatuses} = useContext(TicketDetailsPageContext)
  const {projectPriorities, isLoadingProjectPriorities} = useContext(TicketDetailsPageContext)
  const {ticketDetails, isLoadingTicketDetails} = useContext(TicketDetailsPageContext)
  const {updateTicket, isLoadingTicketOperation} = useContext(TicketDetailsPageContext)
  const {isTicketEditable, setIsTicketEditable} = useContext(TicketDetailsPageContext)
  const shouldLoadTicket = isLoadingTicketDetails && ticketDetails?.length === 0
  const [clickedAction, setClickedAction] = useState<any>(null)
  const {customFieldsList, updateCustomField} = useContext(TicketDetailsPageContext)
  const customFormFields = dataToFormFields(customFieldsList)
  const {
    register,
    control,
    reset,
    watch,
    setValue,
    unregister,
    handleSubmit,
    formState: {dirtyFields},
  } = useForm<any>({
    defaultValues: defaultProjectTicketValues,
  })

  useEffect(() => {
    setDefaultTicketDetails(ticketDetails, customFieldsList, reset)
  }, [ticketDetails, customFieldsList])

  const updatedData = getUpdatedData(dirtyFields, watch())

  const onSave = async () => {
    if (!isEmpty(updatedData) && !isLoadingTicketOperation && !isLoadingTicketDetails) {
      updatedData?.project_customfield_id
        ? updateCustomField(updatedData)
        : await updateTicket(updatedData)
      unregister('projectCustomfieldId')
      reset(watch(), {keepValues: true})
      setIsTicketEditable(false)
    } else {
      setIsTicketEditable(false)
    }
  }

  const onCancel = () => {
    setDefaultTicketDetails(ticketDetails, customFieldsList, reset)
    setClickedAction('cancel')
    setIsTicketEditable(false)
  }

  useEffect(() => {
    if (!isTicketEditable) {
      onSave()
    } else {
      setClickedAction(null)
    }
  })

  return (
    <>
      <TicketDetailsPageHeader register={register} onSave={onSave} ticketTitle={watch('title')} />
      <div className={`position-relative h-full ${shouldLoadTicket ? 'min-h-350px' : ''}`}>
        <div className='row gx-10'>
          <div className='col-md-8'>
            {(ticketDetails?.description || isTicketEditable || isLoadingTicketDetails) && (
              <TextEditor
                id='ticket-description'
                className={`${!isTicketEditable ? '' : 'mb-10'}`}
                editorClass={!isTicketEditable ? 'border-0' : ''}
                inputClass={
                  isLoadingTicketDetails && ticketDetails?.length === 0 ? `min-h-350px` : ''
                }
                registerKey='description'
                control={control}
                defaultValue={ticketDetails?.description}
                isLoadingOperation={isLoadingTicketOperation}
                isLoadingData={isLoadingTicketDetails}
                isReadOnly={!isTicketEditable}
                isClearChanges={clickedAction === 'cancel'}
              />
            )}

            {!isTicketEditable && <CommentSectionNavigation />}
          </div>
          <div className='col-md-4 position-relative'>
            <SectionForm
              id='create-ticket-status'
              className='border border-2 rounded-2 p-8 position-sticky top-0'
              commonClassNames='row d-flex mb-5 align-items-center'
              commonLabelClass='col-md-5'
              commonInputClass='col'
              register={register}
              control={control}
              inputs={[
                {
                  id: 'assignee',
                  label: 'Assignee',
                  isSelect: true,
                  placeholder: 'Unassigned',
                  isNullable: true,
                  options: projectMembers,
                  labelKey: 'name',
                  valueKey: 'username',
                  registerKey: 'assignedTo',
                  isLoading:
                    isLoadingProjectMembers ||
                    shouldLoadTicket ||
                    (isLoadingTicketOperation && dirtyFields?.assignedTo),
                },
                {
                  id: 'kanban-stage',
                  label: 'Kanban Stage',
                  isSelect: true,
                  options: projectKanbanStages,
                  registerOptions: {valueAsNumber: true},
                  valueType: 'number',
                  labelKey: 'name',
                  valueKey: 'column_id',
                  registerKey: 'currentColumnId',
                  isLoading:
                    isLoadingProjectKanbanStages ||
                    shouldLoadTicket ||
                    (isLoadingTicketOperation && dirtyFields?.currentColumnId),
                },
                {
                  id: 'module',
                  label: 'Module',
                  isSelect: true,
                  options: projectModules,
                  registerOptions: {valueAsNumber: true},
                  labelKey: 'name',
                  valueKey: 'module_id',
                  registerKey: 'moduleId',
                  isLoading:
                    isLoadingProjectModules ||
                    shouldLoadTicket ||
                    (isLoadingTicketOperation && dirtyFields?.moduleId),
                },
                {
                  id: 'status',
                  label: 'Status',
                  isSelect: true,
                  placeholder: 'No Status',
                  isNullable: true,
                  options: projectStatuses,
                  registerOptions: {valueAsNumber: true},
                  placeholderValue: 0,
                  labelKey: 'label',
                  valueKey: 'id',
                  registerKey: 'status',
                  isLoading:
                    isLoadingProjectStatuses ||
                    shouldLoadTicket ||
                    (isLoadingTicketOperation && dirtyFields?.status),
                },
                {
                  id: 'priority',
                  label: 'Priority',
                  isSelect: true,
                  placeholder: 'No Priority',
                  isNullable: true,
                  options: projectPriorities,
                  registerOptions: {valueAsNumber: true},
                  placeholderValue: 0,
                  labelKey: 'label',
                  valueKey: 'id',
                  registerKey: 'priority',
                  isLoading:
                    isLoadingProjectPriorities ||
                    shouldLoadTicket ||
                    (isLoadingTicketOperation && dirtyFields?.priority),
                },
                {
                  id: 'estimation-time',
                  label: 'Estimation',
                  isInputTime: true,
                  registerKey: 'estimation',
                  isLoading:
                    shouldLoadTicket || (isLoadingTicketOperation && dirtyFields?.estimation),
                },
                {
                  id: 'spent-time',
                  label: 'Spent Time',
                  isInputTime: true,
                  registerKey: 'spentTime',
                  isLoading:
                    shouldLoadTicket || (isLoadingTicketOperation && dirtyFields?.spentTime),
                  isTempAddition: true,
                },
                {
                  id: 'due-date',
                  label: 'Due Date',
                  isInputDate: true,
                  registerKey: 'dueDate',
                  isLoading: shouldLoadTicket || (isLoadingTicketOperation && dirtyFields?.dueDate),
                },
                ...(customFormFields?.length > 0
                  ? [
                      {
                        id: 'line-break',
                        label: 'Additional Fields',
                        inputClass: 'd-none',
                        labelClass:
                          'fw-bold fs-3 mt-5 mb-5 text-center col-md-12 border-bottom border-top p-3',
                      },
                    ]
                  : []),
                ...(customFormFields?.length > 0
                  ? customFormFields.map((customField: any) => ({
                      ...customField,
                      // onChange: () => setValue('projectCustomfieldId', customField?.fieldId),
                    }))
                  : []),
              ]}
            />
          </div>
        </div>
        {isTicketEditable && (
          <div className='d-flex gap-3 justify-content-end align-items position-sticky bottom-0 py-5 pb-8 mt-10 bg-white border-top'>
            <button type='button' className='btn btn-outline bg-white' onClick={onCancel}>
              Cancel
            </button>
            <button type='button' className='btn btn-primary' onClick={handleSubmit(onSave)}>
              {isLoadingTicketOperation ? 'Saving...' : 'Save'}
              {isLoadingTicketOperation && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default TicketForm
