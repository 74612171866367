import {SortableContext} from '@dnd-kit/sortable'
import {useMemo} from 'react'
import RowContainer from './RowContainer'
import NoDataFound from '../../NoDataFound'
import Loading from '../../../modules/loading'

function ColumnContainer({tableData, isLoading, isDraggable}: any) {
  const columns: any = tableData.length > 0 ? tableData[0].tableData : []

  const rowsIds: any = useMemo(() => {
    return tableData.map((row: any) => row.id)
  }, [tableData])

  return (
    <div className='card-body p-0'>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-5'>
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                {columns?.map((column: any, index: number) => (
                  <th key={index} className={column.headerClass}>
                    {column.columnName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <SortableContext items={rowsIds}>
                {tableData.map((tableRowData: any) => (
                  <RowContainer
                    key={tableRowData.id}
                    row={tableRowData}
                    isDraggable={isDraggable}
                  />
                ))}
              </SortableContext>
              {!isLoading && tableData?.length === 0 && (
                <NoDataFound colspan={columns?.length || 0} />
              )}
            </tbody>
          </table>
          {isLoading && <Loading />}
        </div>
      </div>
    </div>
  )
}

export default ColumnContainer
