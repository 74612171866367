import {isEmpty} from '../../../utils/common'

// Helper function to check if a value is an object (and not an array)
const isObject = (value: any): boolean => {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

// Helper function to check if a value is an array
const isArray = (value: any): boolean => {
  return Array.isArray(value)
}

// Recursive helper function to compare two arrays, accounting for any type of elements (primitives, objects, arrays, etc.)
const compareArrays = (defaultArr: any[], formArr: any[]): any[] => {
  if (defaultArr.length !== formArr.length) return formArr

  const differences: any[] = []

  // Recursive function to deeply compare data
  const compareData = (defaultVal: any, formVal: any): any => {
    if (isObject(defaultVal) && isObject(formVal)) {
      const nestedModifiedFields: {[key: string]: any} = {}
      for (const key in formVal) {
        if (formVal.hasOwnProperty(key)) {
          const nestedChanges = compareData(defaultVal[key], formVal[key])
          if (!isEmpty(nestedChanges)) {
            nestedModifiedFields[key] = nestedChanges
          }
        }
      }
      return nestedModifiedFields
    } else if (isArray(defaultVal) && isArray(formVal)) {
      return compareArrays(defaultVal, formVal)
    } else if (defaultVal !== formVal) {
      return formVal
    }
    return {}
  }

  for (let i = 0; i < formArr.length; i++) {
    const defaultValue = defaultArr[i]
    const formValue = formArr[i]

    if (isObject(defaultValue) || isArray(defaultValue)) {
      const result = compareData(defaultValue, formValue)
      if (!isEmpty(result)) {
        differences.push(result)
      } else {
        differences.push(undefined)
      }
    } else if (defaultValue !== formValue) {
      differences.push(formValue)
    } else {
      differences.push(undefined)
    }
  }

  return differences.some((item) => item !== undefined) ? differences : []
}

export const getModifiedInputFields = (
  defaultData: Record<string, any>,
  formData: Record<string, any>
): Record<string, any> => {
  if (isEmpty(defaultData) || isEmpty(formData)) return formData || {}

  const modifiedFields: {[key: string]: any} = {}

  // Recursive function to deeply compare data
  const compareData = (defaultVal: any, formVal: any): any => {
    if (isObject(defaultVal) && isObject(formVal)) {
      const nestedModifiedFields: {[key: string]: any} = {}
      for (const key in formVal) {
        if (formVal.hasOwnProperty(key)) {
          const nestedChanges = compareData(defaultVal[key], formVal[key])
          if (!isEmpty(nestedChanges)) {
            nestedModifiedFields[key] = nestedChanges
          }
        }
      }
      return nestedModifiedFields
    } else if (isArray(defaultVal) && isArray(formVal)) {
      return compareArrays(defaultVal, formVal)
    } else if (defaultVal !== formVal) {
      return formVal
    }
    return {}
  }

  // Iterate over formData and perform deep comparison
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const result = compareData(defaultData[key], formData[key])
      if (!isEmpty(result)) {
        modifiedFields[key] = result
      }
    }
  }

  return modifiedFields
}
