import {useEffect, useMemo, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import {Controller} from 'react-hook-form'
import {dataToSelectOptions, isEmpty} from '../../utils/common'

export interface MultipleSelectProps {
  id?: string
  options: any
  defaultValues?: any
  placeholder?: string | undefined
  disabled?: boolean
  error?: any
  label?: string
  isRequired?: boolean
  isOpen?: boolean
  className?: string
  onMenuToggle?: any
  handleSelected?: (values: any) => void
  control?: any
  inputClass?: string
  registerKey?: string
  rules?: any
  isLoading?: boolean
  isReadOnly?: boolean
  hasSelectAll?: boolean
  labelClass?: string
  labelKey?: string
  valueKey?: string
  valueType?: any
}

function MultipleSelect({
  id,
  options,
  defaultValues,
  placeholder = 'Select',
  disabled,
  error,
  isOpen,
  label,
  isRequired,
  className,
  inputClass = '',
  onMenuToggle,
  handleSelected,
  registerKey,
  control,
  rules,
  isLoading,
  isReadOnly = false,
  hasSelectAll = true,
  labelClass = '',
  labelKey = 'label',
  valueKey = 'value',
  valueType = 'string',
}: MultipleSelectProps) {
  const [selected, setSelected] = useState<Array<{label: string; value: string}>>([])
  const [registeredValue, setRegisteredValue] = useState<any[]>([])
  const [isDefaultSelected, setIsDefaultSelected] = useState(false)

  const formattedOptions =
    options && options?.length > 0
      ? labelKey && valueKey
        ? dataToSelectOptions(options, labelKey, valueKey, valueType)
        : dataToSelectOptions(options, 'label', 'value', valueType)
      : []

  // Memoize default selected values
  const defaultSelected = useMemo(() => {
    if (!isEmpty(options)) {
      if (!isEmpty(defaultValues) && registerKey && isEmpty(registeredValue)) {
        return options.filter((option: any) => defaultValues?.includes(option?.value))
      }
      if (!isEmpty(registeredValue) && registerKey && isEmpty(defaultValues)) {
        return options.filter((option: any) => registeredValue?.includes(option?.value))
      }
      if (!isEmpty(registeredValue) && registerKey && isEmpty(defaultValues)) {
        return []
      }
      if (!isEmpty(defaultValues)) {
        return defaultValues
      }
    }
  }, [defaultValues, registeredValue, options, registerKey])

  useEffect(() => {
    if (!isEmpty(defaultSelected) && !isDefaultSelected) {
      setSelected(defaultSelected)
      setIsDefaultSelected(true)
    }
  }, [defaultSelected, isDefaultSelected])

  const renderMultiSelect = (onChange?: (values: string[]) => void) => (
    <MultiSelect
      className={`p-0 form-select border-0 ${inputClass} ${error ? 'is-invalid' : ''}`}
      options={formattedOptions}
      value={selected}
      onChange={(values: any) => {
        setSelected(values)
        handleSelected?.(values)
        onChange?.(values?.map((value: any) => value?.value))
      }}
      labelledBy={placeholder}
      isLoading={isLoading}
      disabled={disabled || isReadOnly}
      isOpen={isOpen}
      onMenuToggle={onMenuToggle}
      overrideStrings={{selectSomeItems: placeholder}}
      hasSelectAll={hasSelectAll}
    />
  )

  return (
    <div className={className} id={id}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      {registerKey && control ? (
        <Controller
          name={registerKey}
          control={control}
          rules={rules}
          render={({field: {onChange, value}}) => {
            setRegisteredValue(value)
            return renderMultiSelect(onChange)
          }}
        />
      ) : (
        renderMultiSelect(() => {})
      )}
      {error?.message && <p className='invalid-feedback'>{error.message}</p>}
    </div>
  )
}

export default MultipleSelect
