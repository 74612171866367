import {useContext, useState} from 'react'
import DndTable from '../../../../../components/dndTable/DndTable'
import AddBoardCustomFieldsModal from '../../modals/AddBoardCustomFieldsModal'
import {BoardSettingsPageContext} from '../../../context'
import CustomFieldActionsOverlay from '../../overlays/CustomFieldActionsOverlay'

export default function CustomFieldsTable() {
  const {
    updateCustomFieldTableSorting,
    boardCustomFieldsList,
    isLoadingBoardCustomFieldsList,
    hasBoardSettingsPermission,
  } = useContext(BoardSettingsPageContext)

  const [showModal, setShowModal] = useState(false)

  const handleUpdatedTableRows = (tableData: any) => {
    const customFieldsTableRowOrder = {
      project_custom_field_ids: tableData.map((item: any) => item.id),
    }
    updateCustomFieldTableSorting(customFieldsTableRowOrder)
  }

  const tableData = boardCustomFieldsList?.map((cField: any) => {
    const rows = [
      {
        columnName: 'NAME',
        headerClass: 'min-w-200px',
        columnData: cField.custom_field_name,
      },
      {
        columnName: 'FIELD TYPE',
        headerClass: 'w-150px',
        columnData: cField.field_type,
      },
      {
        columnName: 'USED IN CARDS',
        headerClass: 'w-150px text-center',
        columnData: <div className='text-center'>{cField.card_counts}</div>,
      },
    ]

    if (hasBoardSettingsPermission) {
      rows.push({
        columnName: 'ACTION',
        headerClass: 'w-80px text-center',
        columnData: (
          <CustomFieldActionsOverlay customFieldId={cField.id} customFieldData={cField} />
        ),
      })
    }

    return {
      id: cField.id,
      tableData: rows,
    }
  })

  return (
    <div className='position-relative'>
      <DndTable
        data={tableData}
        isLoading={isLoadingBoardCustomFieldsList}
        handleUpdatedTableRows={handleUpdatedTableRows}
        isDraggable={hasBoardSettingsPermission}
      />
      {hasBoardSettingsPermission && (
        <div className='d-flex justify-content-end'>
          {!isLoadingBoardCustomFieldsList && (
            <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
              <i className='fa-solid fa-plus'></i>Add Custom Field
            </button>
          )}
          {showModal && (
            <AddBoardCustomFieldsModal
              show={showModal}
              isEdit={false}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      )}
    </div>
  )
}
