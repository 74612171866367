import Loading from '../../../loading'
import useGetProjectMembersList from '../../hooks/useGetProjectMembersList'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectMembersListingModalTypes} from '../../interfaces'

const ProjectMembersListingModal = ({
  show,
  onClose,
  projectId,
}: ProjectMembersListingModalTypes) => {
  const {projectMembersList, isLoading} = useGetProjectMembersList(projectId)

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={'Project Members'}
      body={
        <div
          className={`position-relative ${isLoading ? 'min-h-300px' : ''} mh-600px overflow-auto`}
        >
          {isLoading && <Loading />}
          <table className='w-100 d-flex flex-column gap-5'>
            {projectMembersList?.map((member: any) => (
              <tr className='d-flex gap-5 justify-content-between align-items-center '>
                <td className='d-flex gap-4 align-items-center w-100'>
                  <div className='d-flex justify-content-center align-items-center bg-primary bg-opacity-15 fw-bold fs-4 w-35px h-35px rounded rounded-circle '>
                    {member?.name?.charAt(0)?.toUpperCase()}
                  </div>
                  <div className='align-items-center'>{member?.name}</div>
                </td>

                <td className='w-100 d-flex align-content-center justify-content-end'>
                  <div className='align-items-center'>{member?.role}</div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      }
    />
  )
}

export default ProjectMembersListingModal
