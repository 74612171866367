import {convertKeysToSnakeCase} from '../../../utils/common'

export const defaultProjectTicketValues = (
  columns: any,
  modules: any,
  isCreateFromBoard: boolean,
  columnId: number | null,
  moduleId: number | null
) => {
  return {
    currentColumnId: isCreateFromBoard ? columnId : columns?.[0]?.column_id,
    moduleId: isCreateFromBoard ? moduleId : modules?.[0]?.module_id,
    status: 0,
    priority: 0,
    title: '',
    description: '',
    assignedTo: '',
    spentTime: '',
    estimation: '',
    dueDate: null,
    isArchived: false,
  }
}

export function formatCreateTicketPayload(
  data: any,
  defaultValues: any,
  columns: any,
  modules: any
) {
  const clonedData = {...data}

  const result = Object.keys(defaultValues).reduce(
    (acc, key) => {
      if (acc[key] === undefined && defaultValues[key] !== undefined) {
        acc[key] = defaultValues[key]!
      }
      return acc
    },
    {...clonedData}
  )

  const {moduleId, ...remainingData} = result
  const payload = convertKeysToSnakeCase(remainingData)

  return {payload, moduleId}
}
