import {useContext} from 'react'
import {dataToModulesListing} from '../../utils/module'
import {ProjectsMainPageContext} from '../../context'
import useApi from '../../../../services/useApi'

const useGetBoardModulesList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectsMainPageContext)

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/modules`, {
    queryId: 'board-modules',
  })

  return {
    boardModulesList: dataToModulesListing(response?.data),
    isLoading: isFetching,
  }
}

export default useGetBoardModulesList
