import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'
import {useState} from 'react'

const useProjectMembersOperations = () => {
  const Api = useApi()
  const {projectId} = useParams()
  const [isLoading, setLoading] = useState(false)

  const addProjectMembers = (payload: any) => {
    return Api.post(`/agile/projects/${projectId}/members`, payload, {
      setLoading: setLoading,
      refetch: 'project-members-list',
    })
  }

  const removeProjectMember = (memberID: number) => {
    return Api.delete(
      `/agile/projects/${projectId}/members/${memberID}`,
      {},
      {
        setLoading: setLoading,
        refetch: 'project-members-list',
      }
    )
  }

  return {
    addProjectMembers,
    removeProjectMember,
    isLoading,
  }
}

export default useProjectMembersOperations
