import Api from '../../../services/Api'


// ----- Project Members Services -----------

export function getGlobalProjectsMembersDropdownList() {
  const url = `/agile/projects/permitted/members`
  return Api.get(url).then((d: any) => {
    return d.data
  })
}

export function getGlobalProjectsMembersList(filters: any) {
  const url = `/agile/projects/members`
  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

export function addGProjectMember(data: any) {
  const url = `/agile/projects/members`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function removeGProjectMember(globalMemberUsername: string) {
  const url = `/agile/projects/members/${globalMemberUsername}`

  return Api.delete(url, null, null, null).then((d: any) => {
    return d
  })
}

// ----- Custom Fields Services -----------

export function getAllGlobalCustomFields(filters: any) {
  const url = `/agile/projects/custom-fields`
  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

export function addGlobalCustomField(data: any) {
  const url = `/agile/projects/custom-fields`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function editGlobalCustomField(cFieldId: string, data: any) {
  const url = `/agile/projects/custom-fields/${cFieldId}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function removeGlobalCustomField(cFieldId: string) {
  const url = `/agile/projects/custom-fields/${cFieldId}`

  return Api.delete(url, null, null, null).then((d: any) => {
    return d
  })
}

// ----- Settings page tables sorting Services -----------
export function updateCustomFieldTableSorting(tableName: any, data: any) {
  const url = `/agile/projects/${tableName}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function updateDefaultColumnsTableSorting(tableName: any, data: any) {
  const url = `/agile/projects/default/${tableName}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}


// ----- Default Columns Services -----------

export function getAllDefaultColumns(filters: any) {
  const url = `/agile/projects/default/columns`
  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

export function addGlobalDefaultColumn(data: any) {
  const url = `/agile/projects/default/columns`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function editGlobalDefaultColumn(defaultColumnId: string, data: any) {
  const url = `/agile/projects/default/columns/${defaultColumnId}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function removeGlobalDefaultColumn(defaultColumnId: string) {
  const url = `/agile/projects/default/columns/${defaultColumnId}`

  return Api.delete(url, null, null, null).then((d: any) => {
    return d
  })
}