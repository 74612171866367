import {convertKeysToSnakeCase, isEmpty} from '../../../utils/common'

export const defaultProjectTicketValues = {
  currentColumnId: 0,
  moduleId: 0,
  status: 0,
  priority: 0,
  title: '',
  description: '<p></p>',
  assignedTo: '',
  spentTime: '',
  estimation: '',
  // dueDate: null,
}

export const setDefaultTicketDetails = (ticketDetails: any, customFieldsList: any, reset: any) => {
  if (isEmpty(ticketDetails)) return
  return reset({
    assignedTo: ticketDetails?.assignee,
    currentColumnId: ticketDetails?.columnID,
    moduleId: ticketDetails?.moduleID,
    status: ticketDetails?.status,
    priority: ticketDetails?.priority,
    title: ticketDetails?.title,
    description: ticketDetails?.description,
    spentTime: ticketDetails?.spent_time,
    estimation: ticketDetails?.estimation,
    // dueDate: ticketDetails?.due_date,
    strValue: customFieldsList?.str_value,
    floatValue: customFieldsList?.float_value,
  })
}

export const getUpdatedData = (dirtyFields: any, formData: any) => {
  const updatedData = Object.keys(dirtyFields).reduce((acc: any, key) => {
    acc[key] = formData[key]
    return acc
  }, {})
  if (formData?.projectCustomfieldId) {
    updatedData.projectCustomfieldId = formData.projectCustomfieldId
  }
  return convertKeysToSnakeCase(updatedData)
}

export const dataToFormFields = (customFields: any) => {
  if (isEmpty(customFields)) return

  return customFields.map((customField: any) => {
    const field: any = {
      id: `custom-field-${customField.id}`,
      fieldId: customField?.id,
      label: customField.name,
    }

    if (customField.type === 'text') {
      // field.registerKey = 'strValue'
      field.defaultValues = customField.str_value
    }

    if (customField.datatype === 'group' && !customField.is_multiselect_group) {
      field.isSelect = true
      // field.registerKey = 'strValue'
      field.defaultValues = customField.str_value
      field.options = customField?.group_values
        ?.split(',')
        .map((val: any) => ({label: val.trim(), value: val.trim()}))
    }

    if (customField.datatype === 'group' && customField.is_multiselect_group) {
      field.isMultiSelect = true
      // field.registerKey = 'strValue'
      field.defaultValues = customField.str_value
      field.options = customField?.group_values
        ?.split(',')
        .map((val: any) => ({label: val.trim(), value: val.trim()}))
    }

    if (customField.datatype === 'integer') {
      field.isNumberInput = true
      // field.registerKey = 'intValue'
      field.defaultValues = customField.int_value
    }

    if (customField.datatype === 'float') {
      field.isNumberInput = true
      field.isFloat = true
      // field.registerKey = 'floatValue'
    }

    if (customField.datatype === 'date') {
      field.isInputDate = true
      //   field.registerKey = 'strValue'
      //   field.defaultValues = customField.str_value
    }
    return field
  })
}
