import useApi from '../../../../services/useApi'
import {useParams} from 'react-router-dom'
import {useState} from 'react'
import {useAppSelector} from '../../../../redux/useTypedSelector'

function useColumnOperations() {
  const Api = useApi()
  const {user} = useAppSelector((state) => state.auth)
  const {projectId} = useParams()
  const [isLoading, setLoading] = useState(false)

  async function createColumn(payload: any) {
    return Api.post(
      `/agile/projects/${projectId}/columns`,
      {
        name: payload['column_name'],
        description: payload['description'],
        is_archived: false,
      },
      {
        successMessage: 'Column created successfully.',
        setLoading: setLoading,
        refetch: 'board-columns',
      }
    )
  }

  async function updateColumn(columnId: string, payload: any) {
    return Api.patch(
      `/agile/projects/${projectId}/columns/${columnId}`,
      {
        name: payload['column_name'],
        description: payload['description'],
        updated_by: user['username'],
      },
      {
        successMessage: 'Column updated successfully.',
        setLoading: setLoading,
        refetch: 'board-columns',
      }
    )
  }

  async function updateColumnTableSorting(sortOrder: any) {
    const tableName = 'columns'
    return Api.patch(`/agile/projects/${projectId}/${tableName}`, sortOrder, {
      successMessage: 'Updated successfully',
      setLoading: setLoading,
    })
  }

  async function deleteColumn(columnId: string) {
    return Api.delete(
      `/agile/projects/${projectId}/columns/${columnId}`,
      {},
      {
        successMessage: 'Column deleted successfully.',
        setLoading: setLoading,
        refetch: 'board-columns',
      }
    )
  }

  return {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    isLoading,
  }
}

export default useColumnOperations
