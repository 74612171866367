import Accordion from '../../../components/Accordion'
import usePermission from '../../../hook/usePermission'
import {BoardSettingsPageContext} from '../context'
import useColumnOperations from '../hooks/boardSettings/useColumnOperations'
import useCustomFieldsOperations from '../hooks/boardSettings/useCustomFieldsOperations'
import useGetBoardColumnsList from '../hooks/boardSettings/useGetBoardColumnsList'
import useGetBoardCustomFieldsList from '../hooks/boardSettings/useGetBoardCustomFieldsList'
import useGetBoardModulesList from '../hooks/boardSettings/useGetBoardModulesList'
import useModulesOperations from '../hooks/boardSettings/useModulesOperations'
import ColumnsTable from '../sections/tables/boardSettingTables/ColumnsTable'
import CustomFieldsTable from '../sections/tables/boardSettingTables/CustomFieldsTable'
import ModulesTable from '../sections/tables/boardSettingTables/ModulesTable'

function BoardSetting() {
  const {hasPermission} = usePermission()
  const hasBoardSettingsPermission = hasPermission('projects_all projects', 'write')
  const {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    isLoading: isLoadingColumnOperation,
  } = useColumnOperations()
  const {
    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    isLoading: isLoadingCustomFieldOperation,
  } = useCustomFieldsOperations()
  const {boardColumnsList, isLoading: isLoadingBoardColumnsList} = useGetBoardColumnsList()
  const {boardModulesList, isLoading: isLoadingBoardModulesList} = useGetBoardModulesList()
  const {boardCustomFieldsList, isLoading: isLoadingBoardCustomFieldsList} =
    useGetBoardCustomFieldsList()
  const {
    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    isLoading: isLoadingModulesOperation,
  } = useModulesOperations()

  const contextValue = {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    isLoadingColumnOperation,

    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    isLoadingCustomFieldOperation,

    boardColumnsList,
    isLoadingBoardColumnsList,

    boardCustomFieldsList,
    isLoadingBoardCustomFieldsList,

    boardModulesList,
    isLoadingBoardModulesList,

    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    isLoadingModulesOperation,

    hasBoardSettingsPermission,
  }

  const boardSettingTables = [
    {
      title: 'Columns',
      id: 'project-columns-table',
      content: <ColumnsTable />,
      isLoading: isLoadingColumnOperation,
    },
    {
      title: 'Modules',
      id: 'project-modules-table',
      content: <ModulesTable />,
      isLoading: isLoadingModulesOperation,
    },
    {
      title: 'Custom Fields',
      id: 'project-cFields-table',
      content: <CustomFieldsTable />,
      isLoading: isLoadingCustomFieldOperation,
    },
  ]

  return (
    <BoardSettingsPageContext.Provider value={contextValue}>
      <div className='agile-accordion-section'>
        <Accordion items={boardSettingTables} defaultOpen='All' />
      </div>
    </BoardSettingsPageContext.Provider>
  )
}

export default BoardSetting
