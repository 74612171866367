import {formatDate} from '../../../../../utils/date'

const MemberPageTableRow = ({row, actionComponent}: any) => {
  return (
    <tr>
      <td>
        <div className='align-items-center'>{row['name']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['role']}</div>
      </td>
      <td>
        <div className='align-items-center'>{formatDate(row['created_at'], false)}</div>
      </td>
      {actionComponent && actionComponent(row)}
    </tr>
  )
}

export default MemberPageTableRow
