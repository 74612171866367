import {useContext, useEffect, useState} from 'react'
import {Mention, MentionsInput} from 'react-mentions'
import classNames from '../../utils/reactMention.module.css'
import classNamesReadOnly from '../../utils/reactMentionReadOnly.module.css'
import {TicketDetailsPageContext} from '../../context'
import store from '../../../../redux/store'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import useGetProjectMembers from '../../hooks/useGetProjectMembers'

export const CommentsTab = () => {
  const {
    createComment,
    editComment,
    commentsList,
    isLoadingCommentsList,
    isLoadingCommentOperation,
    hasTicketWritePermission,
    deleteComment,
  } = useContext(TicketDetailsPageContext)
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()
  const [showModal, setShowModal] = useState(false)
  const [newCommentValue, setNewCommentValue] = useState('')
  const [selectedBtn, setSelectedBtn] = useState('')
  const [selectedCommentId, setSelectedCommentId] = useState(null)
  const [commentValue, setCommentValue] = useState()
  const user: any = store.getState()?.auth?.user

  useEffect(() => {
    if (isLoadingCommentsList) {
    } else {
      setNewCommentValue('')
      setSelectedCommentId(null)
    }
  }, [isLoadingCommentsList])

  const handleAction = (selectedBtn: string, selectedCommentId: any, commentValue?: any) => {
    setSelectedBtn(selectedBtn)
    setSelectedCommentId(selectedCommentId)
    commentValue && setCommentValue(commentValue)
    selectedBtn === 'delete' && setShowModal(true)
  }

  const handleAddComment = () => {
    const commentData = {comment: newCommentValue}
    createComment(commentData)
  }

  const handleSaveComment = async () => {
    const commentData = {comment: commentValue}
    await editComment(selectedCommentId, commentData)
  }

  const commentMentionUserList = projectMembers?.map((user: any) => ({
    id: user.username,
    display: user.name,
  }))

  return (
    <>
      <div className='d-flex flex-column gap-8'>
        {hasTicketWritePermission && (
          <MentionsInput
            placeholder='Add comment here'
            classNames={classNames}
            onChange={(e: any) => setNewCommentValue(e.target.value)}
            allowSpaceInQuery={true}
            value={newCommentValue}
          >
            <Mention
              className={classNames.mentions__mention}
              trigger='@'
              data={commentMentionUserList}
              appendSpaceOnAdd={true}
              isLoading={isLoadingProjectMembers}
              markup={`<p>__display__#__id__</p>`}
              displayTransform={(id, display) => `@${display}`}
              renderSuggestion={(entry) => {
                return (
                  <div>
                    <span className='fw-bolder   text-gray-700'>{entry.display}</span>{' '}
                    <span className='text-muted '>{`(${entry.id})`}</span>
                  </div>
                )
              }}
            />
          </MentionsInput>
        )}

        {newCommentValue.trim() !== '' && (
          <div className='d-flex justify-content-end gap-3'>
            <button onClick={() => setNewCommentValue('')} type='button' className='btn btn-light'>
              Cancel
            </button>
            <button onClick={handleAddComment} type='button' className='btn btn-primary'>
              {!isLoadingCommentOperation && <span className='indicator-label'>Add Comment</span>}
              {isLoadingCommentOperation && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}

        {commentsList && commentsList.length !== 0
          ? commentsList.map((comment: any) => (
              <div className='d-flex gap-4'>
                <div className='w-35px h-35px bg-light-primary rounded-circle border border-1 border-primary border-opacity-50 overflow-hidden d-flex justify-content-center  align-items-center'>
                  <span className='fw-bold text-primary fs-3'>{comment.created_by?.charAt(0)}</span>
                </div>
                <div className='d-flex flex-column gap-1 w-100'>
                  <div className='fw-bold'>{comment.created_by}</div>
                  <div>
                    {selectedCommentId === comment.id && selectedBtn === 'edit' ? (
                      <MentionsInput
                        placeholder='Add comment here'
                        classNames={classNames}
                        onChange={(e: any) => setCommentValue(e.target.value)}
                        allowSpaceInQuery={true}
                        value={commentValue}
                      >
                        <Mention
                          className={classNames.mentions__mention}
                          trigger='@'
                          data={commentMentionUserList}
                          appendSpaceOnAdd={true}
                          isLoading={isLoadingProjectMembers}
                          markup={`<p>__display__#__id__</p>`}
                          displayTransform={(id, display) => `@${display}`}
                          renderSuggestion={(entry) => {
                            return (
                              <div>
                                <span className='fw-bolder   text-gray-700'>{entry.display}</span>{' '}
                                <span className='text-muted '>{`(${entry.id})`}</span>
                              </div>
                            )
                          }}
                        />
                      </MentionsInput>
                    ) : (
                      <MentionsInput
                        classNames={classNamesReadOnly}
                        value={comment.comment}
                        disabled={true}
                      >
                        <Mention
                          className={classNamesReadOnly.mentions__mention}
                          trigger='@'
                          data={commentMentionUserList}
                          markup={`<p>__display__#__id__</p>`}
                          displayTransform={(id, display) => `@${display}`}
                        />
                      </MentionsInput>
                    )}
                  </div>
                  {hasTicketWritePermission && user?.name.trim() === comment.created_by.trim() && (
                    <div className='d-flex gap-4'>
                      {selectedCommentId === comment.id && selectedBtn === 'edit' ? (
                        <>
                          <button
                            onClick={handleSaveComment}
                            className='btn btn-link btn-color-muted btn-active-color-primary'
                          >
                            {!isLoadingCommentOperation && !isLoadingCommentsList && (
                              <span className='indicator-label'>Save</span>
                            )}
                            {(isLoadingCommentOperation || isLoadingCommentsList) && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Saving...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                          <span
                            onClick={() => handleAction('cancel', null)}
                            className='btn btn-link btn-color-muted btn-active-color-danger'
                          >
                            cancel
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            onClick={() => handleAction('edit', comment?.id, comment?.comment)}
                            className='btn btn-link btn-color-muted btn-active-color-primary'
                          >
                            Edit
                          </span>

                          <span
                            onClick={() => handleAction('delete', comment?.id)}
                            className='btn btn-link btn-color-muted btn-active-color-danger'
                          >
                            Delete
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
          : null}
      </div>

      {showModal && (
        <>
          <ConfirmationModal
            show={showModal}
            onClose={() => {
              setShowModal(false)
              setSelectedCommentId(null)
            }}
            onAction={() => deleteComment(selectedCommentId)}
            isDataLoading={isLoadingCommentsList}
            isOperationLoading={isLoadingCommentOperation}
          />
        </>
      )}
    </>
  )
}
