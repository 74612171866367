import {dataToColumnsListing} from '../../utils/column'
import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'

const useGetBoardColumnsList = () => {
  const Api = useApi()
  const params = useParams()
  const projectId = params?.['projectId'] as string

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/columns`, {
    queryId: 'board-columns',
  })

  return {
    boardColumnsList: dataToColumnsListing(response?.data),
    isLoading: isFetching,
  }
}

export default useGetBoardColumnsList
