import {useState} from 'react'
import useApi from '../../../services/useApi'
import {useAppSelector} from '../../../redux/useTypedSelector'

const useProjectOperations = () => {
  const Api = useApi()
  const [isLoading, setLoading] = useState(false)
  const {user} = useAppSelector((state: any) => state.auth)

  const createNewProjectDetails = (payload: any, setError: Function) => {
    return Api.post('/agile/projects', payload, {
      setLoading: setLoading,
      successMessage: 'Project created successfully.',
      refetch: 'projects-list',
      setUseFormError: setError,
    })
  }

  const updateProjectDetails = (payload: any, projectId: number, setError?: Function) => {
    payload = {
      ...payload,
      updated_by: user['username'],
    }
    return Api.patch(`/agile/projects/${projectId}`, payload, {
      setLoading: setLoading,
      successMessage:
        payload?.isArchived === undefined
          ? 'Project updated successfully'
          : payload?.isArchived
          ? 'Project moved to Archived'
          : 'Project moved to Active',
      refetch: 'projects-list',
      setUseFormError: setError,
    })
  }

  const addProjectToFavorite = (projectId: string) => {
    return Api.post(
      `/agile/projects/${projectId}/fevorite`,
      {},
      {
        setLoading: setLoading,
        refetch: 'projects-list',
        successMessage: ({response}: any) => response?.message,
      }
    )
  }

  return {
    createNewProjectDetails,
    updateProjectDetails,
    addProjectToFavorite,
    isLoading: isLoading,
  }
}

export default useProjectOperations
