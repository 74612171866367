import {useContext} from 'react'
import {ProjectMembersPageContext} from '../../../context'
import DynamicTable from '../../../../../components/DynamicTable/DynamicTable'
import MemberPageTableRow from './MemberPageTableRow'
import MemberActionsOverlay from '../../overlays/MemberActionsOverlay'

export default function MemberPageTable() {
  const {projectMembersList, isLoadingProjectMembersList} = useContext(ProjectMembersPageContext)

  const columns = [
    {key: 'name', label: 'NAME', isSorted: false, headerStyle: 'min-w-250px'},
    {key: 'systemRole', label: 'SYSTEM ROLE', isSorted: false, headerStyle: 'min-w-150px'},
    {key: 'createdAt', label: 'CREATED AT', isSorted: false, headerStyle: 'min-w-200px'},
    {
      key: 'action',
      label: 'ACTION',
      isSorted: false,
      headerStyle: 'w-80px',
      render: (row: any) => <MemberActionsOverlay memberDetails={row} />,
    },
  ]

  return (
    <div className='card-body p-0'>
      <DynamicTable
        data={projectMembersList}
        TableRow={MemberPageTableRow}
        loading={isLoadingProjectMembersList}
        noDataMessage='No members found.'
        sortableColumns={columns}
        permissionPath='projects_all projects'
      />
    </div>
  )
}
