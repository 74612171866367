import useApi from '../../../../services/useApi'

export const useGetDropdownMembers = () => {
  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/members`)

  return {
    membersList: response?.data || [],
    isLoading: isFetching,
  }
}
