import {useEffect, useState} from 'react'
import ColumnContainer from './components/ColumnContainer'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {createPortal} from 'react-dom'
import {handleDragEnd} from './utils/handleDragEnd'
import {handleDragOver} from './utils/handleDragOver'
import {handleDragStart} from './utils/handleDragStart'

function DndTable({
  data,
  isLoading,
  handleUpdatedTableRows,
  isDraggable = true,
  isUpdateOnChange = false,
}: any) {
  const [tableData, setTableData] = useState(data)
  const [activeRow, setActiveRow] = useState<any>(null)

  useEffect(() => {
    setTableData(data)
    //eslint-disable-next-line
  }, [isLoading])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0,
      },
    })
  )

  function onDragStart(event: DragStartEvent) {
    handleDragStart(event, setActiveRow)
  }

  function onDragOver(event: DragOverEvent) {
    handleDragOver(event, tableData, setTableData)
  }

  function onDragEnd(event: DragEndEvent) {
    handleDragEnd(event, handleUpdatedTableRows, tableData, setActiveRow)
  }

  return (
    <div>
      {isDraggable ? (
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          <ColumnContainer tableData={tableData} isLoading={isLoading} isDraggable={isDraggable} />
          {createPortal(
            <DragOverlay>
              {activeRow && (
                <tr className='cursor-grab h-100 w-100 d-flex justify-content-between align-items-center'>
                  <td>{activeRow?.tableData[0]?.columnData}</td>
                </tr>
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      ) : (
        <div>
          <ColumnContainer tableData={tableData} isLoading={isLoading} isDraggable={isDraggable} />
          {createPortal(
            <DragOverlay>
              {activeRow && (
                <tr className='cursor-grab h-100 w-100 d-flex justify-content-between align-items-center'>
                  <td>{activeRow?.tableData[0]?.columnData}</td>
                </tr>
              )}
            </DragOverlay>,
            document.body
          )}
        </div>
      )}
    </div>
  )
}
export default DndTable
