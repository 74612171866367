import {isEmpty} from '../../../utils/common'
import {capitalizeFirstLetter} from '../../../utils/string'

export const dataToModulesListing = (data: any) => {
  if (isEmpty(data)) return []

  return data.map((module: any) => {
    return {
      moduleId: module['module_id'],
      moduleName: capitalizeFirstLetter(module['name']) || '-',
      cards: module['cards'] || 0,
    }
  })
}
