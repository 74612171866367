import useFilters from '../../../hook/useFilters'
import {useLocation} from 'react-router-dom'
import useApi from '../../../services/useApi'

export const useGetProjectsList = () => {
  const Api = useApi()
  const location = useLocation()

  const initialFilters = {
    page: 1,
    limit: 10,
    search: true,
    is_archived: location.pathname === '/projects/archived' ? 'true' : 'false',
    sort_by: 'updated_at/-1',
  }

  const {filters, onSortingChange, onPageChange, onSearch} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery('/agile/projects', {
    queryId: 'projects-list',
    filters: filters,
  })

  return {
    projectsList: response?.data || [],
    isLoading: isFetching,
    pagination: response?.pagination || {},
    filters,
    onPageChange,
    onSearch,
    refetchProjectsList: refetch,
    onSortingChange: onSortingChange,
  }
}
